import { Lookup } from '../../models/layout-metadata.model';
import { Component, EventEmitter, Input, OnInit, Output, inject } from '@angular/core';
import { FormlyFieldConfig, FormlyFormOptions } from '@ngx-formly/core';
import { AbstractControl, FormGroup } from '@angular/forms';
import { PickListValue, Section } from '../../models/layout-metadata.model';
import { LayoutName } from '../../models/integration-config-response.model';
import { MessageService } from '../../utils/messages.service';

@Component({
	selector: 'app-formly',
	templateUrl: './formly.component.html',
	styleUrls: ['./formly.component.scss']
})
export class FormlyComponent implements OnInit {

  loaded = false;

  private messageService = inject(MessageService);

  @Input() recordData: any;
  @Input() module: string;
  @Input() useLayout: boolean;
  @Input() titleCode: string;
  @Input() sending: boolean;
  @Input() zohoType: string;
  @Input() currentDepartmentId: string;

  @Output() emitAction: EventEmitter<any> = new EventEmitter<any>();
  @Output() cancelAction: EventEmitter<any> = new EventEmitter<any>();

  currentLayouts?: any[];
  currentLayoutId = '';
  form = new FormGroup({});
  model: any = {};
  options: FormlyFormOptions = {};
  fields: FormlyFieldConfig[] = [];

  ngOnInit(): void {
    setTimeout(() => {
      this.initFormly();
    },10);
  }

  updateLayout(): void {
    this.initForm();
  }

  getCurrentLayout(layouts: any): LayoutName[] {
    let current = layouts.leads;
    if (this.module.toLowerCase() == 'contacts') current = layouts.contacts;
    if (this.module.toLowerCase() == 'deals') current = layouts.deals;
    if (this.module.toLowerCase() == 'tasks') current = layouts.tasks;
    if (this.module.toLowerCase() == 'calls') current = layouts.calls;
    if (this.module.toLowerCase() == 'events') current = layouts.events;
    if (this.module.toLowerCase() == 'tickets') current = layouts.tickets;
    return current;
  }

  loadedLayouts(): void {
    let layoutsAsString = localStorage.getItem('zohoCrmLayouts');
    if (this.zohoType == 'desk') {
      layoutsAsString = localStorage.getItem('zohoDeskLayouts');
    }

    if (layoutsAsString) {
      const layouts = JSON.parse(layoutsAsString);
      let current = this.getCurrentLayout(layouts);
      const tempLayouts = [];
      current.forEach(e => {
        tempLayouts.push({
          value: e.id,
          label: e.name
        });
        if (e.selected) {
          this.currentLayoutId = e.id ;
        }
      });
      this.currentLayouts = tempLayouts || [];
    } else {
      this.messageService.showMessage('You must synchronize the Layouts','warning', true);
    }
  }

  submit() {
    if (this.form.valid) {
      this.emitAction.emit({
        action: (this.recordData && this.recordData['id']) ? 'update' : 'create',
        values: this.getModel(this.model),
        module: this.module
      });
    }
  }

  getInvalidFields(fields: FormlyFieldConfig[], form: FormGroup, sub: string): string[] {
    let invalidFields = [];
    fields.forEach(field => {
      const control = form.get(field.key as string);
      if (control && control.invalid) {
        if (sub.indexOf('  - ') >= 0) {
          control?.markAsTouched({ onlySelf: true });
          invalidFields.push(sub + (field.props.display_label as string));
        }
      }
      if (field.fieldGroup && field.fieldGroup.length > 0) {
        invalidFields = invalidFields.concat(this.getInvalidFields(field.fieldGroup, control as FormGroup, "  - "));
      }
    });
    return invalidFields;
  }

  markFormControlsAsTouched() {
    if (this.form) {
      Object.keys(this.form.controls).forEach(field => {
        const control = this.form.get(field);
        control?.markAsTouched({ onlySelf: true });
      });
    }
  }

  getFieldByApiName(apiName: string): any {
    let foundField: FormlyFieldConfig ;
    this.fields.forEach(section => {
        section.fieldGroup.forEach(field => {
            if (field.key === apiName) {
                foundField = field;
            }
        });
    });
    return foundField || '';
  }

  getModel(model: any): any {
    const output = [];
    for (const key in model) {
      if (model.hasOwnProperty(key)) {
          const innerObject = model[key];
          for (const innerKey in innerObject) {
              if (innerObject.hasOwnProperty(innerKey)) {
                if (innerObject[innerKey]) {
                  const fields: FormlyFieldConfig = this.getFieldByApiName(innerKey);
                  if (fields) {
                     output.push({
                      key: innerKey,
                      type: this.getTypeFromApiName(innerKey),
                      value: innerObject[innerKey],
                      isCustom: this.getIsCustomFromApiName(innerKey)
                     });
                  }
                }
              }
          }
      }
    }

    if (this.zohoType !== 'desk') {
      if (this.recordData && this.recordData['id']) {
        output.push({
          key: 'id',
          type: 'long',
          value: this.recordData['id'],
          isCustom: false
         });
      }
    }

    if (this.zohoType === 'desk' && this.module === 'tickets') {
      if (this.recordData && this.recordData['id']) {
        output.push({
          key: 'contactId',
          type: 'long',
          value: this.recordData['id'],
          isCustom: false
         });
      }

      if (this.currentDepartmentId) {
        output.push({
          key: 'departmentId',
          type: 'long',
          value: this.currentDepartmentId,
          isCustom: false
         });
      }
    }

    output.push({
      key: 'Layout',
      type: 'long',
      value: this.currentLayoutId,
      isCustom: false
    });

    return output;
  }

  getTypeFromApiName(apiName: string): string {
    let dataType ;
    const metadata = this.getMetaData(this.module.toLowerCase());
    if (metadata) {
      metadata.forEach(section => {
        section.fields.forEach(field => {
          if (field.apiName === apiName) {
            dataType = field.dataType;
          }
        });
      });
    }
    return dataType || 'text';
  }

  getIsCustomFromApiName(apiName: string): boolean {
    let isCustom ;
    const metadata = this.getMetaData(this.module.toLowerCase());
    if (metadata) {
      metadata.forEach(section => {
        section.fields.forEach(field => {
          if (field.apiName === apiName) {
            return isCustom = field.customField;
          }
        });
      });
    }
    return isCustom || false;
  }

  createFormlyFieldConfig(): FormlyFieldConfig[] {
    const sections = this.getMetaData(this.module);
    const _sections: FormlyFieldConfig[] = [];

    sections.forEach(section => {
      const fieldsGroup: FormlyFieldConfig[] = [];
      section.fields.forEach(_field => {
        const fieldGroup: FormlyFieldConfig = {
          key: _field.apiName,
          defaultValue: this.getDefaultData(_field.apiName, _field.dataType.toLowerCase()),
          type: this.getType(_field.dataType.toLowerCase()),
          props: {
            display_label: _field.displayLabel,
            placeholder: this.usePlaceholder(_field.dataType.toLowerCase(), _field.displayLabel),
            maxLength: _field.length,
            required: this.getRequired(_field.required, _field.apiName),
            readonly: this.getReadOnly(_field.readOnly, _field.apiName, _field.dataType.toLowerCase()),
            label: this.showLabel(_field.dataType.toLowerCase(),_field.displayLabel),
            options: this.getOptions(_field.pickListValues, _field.dataType.toLowerCase()                                                     ),
            currency: 'EUR',
            rows: 4,
            api_name: this.getLookUpApiName(_field.lookup, _field.dataType.toLowerCase()),
            module: this.module,
            zohoType: this.zohoType,
            departmentId: this.currentDepartmentId,
            change: (field, event)=>{
              this.onFormFieldChanges(field, event);
            }
          },
          validators: this.getValidators(_field.dataType.toLowerCase()),

        };
        if (this.validateToAdd(_field.apiName)) {
          fieldsGroup.push(fieldGroup);
        }
      });
      const _section: FormlyFieldConfig = {
        key: this.toCamelCase(section.displayLabel),
        wrappers: ['card'],
        props: { display_label: section.displayLabel },
        fieldGroup: fieldsGroup
      };
      _sections.push(_section);
    });
    return _sections;
  }

  toCamelCase(input: string): string {
    return input.replace(/[-_]+(.)?/g, (_, c) => c ? c.toUpperCase() : '');
  }

  getType(type: string): string {
    if (type == 'text')  return 'input-custom';
    if (type == 'textarea')  return 'textarea-custom';
    if (type == 'bigint')  return 'input-custom';
    if (type == 'email')  return 'input-custom';
    if (type == 'ownerlookup')  return 'lookup-autocomplete-input';
    if (type == 'website')  return 'input-custom';
    if (type == 'phone')  return 'input-custom';
    if (type == 'profileimage')  return 'input-custom';
    if (type == 'picklist')  return 'select-custom';
    if (type == 'datetime')  return 'datetime-custom';
    if (type == 'date')  return 'date-custom';
    if (type == 'boolean')  return 'custom-checkbox';
    if (type == 'lookup')  return 'lookup-autocomplete-input';
    if (type == 'checkbox')  return 'custom-checkbox';
    if (type == 'multiselectpicklist')  return 'multi-select';
    return 'input-custom';
  }
/*
  getType(type: string): string {
    if (type == 'text')  return 'input-custom';
    if (type == 'textarea')  return 'textarea-custom';
    if (type == 'picklist')  return 'select-custom';
    if (type == 'datetime')  return 'datetime-custom';
    if (type == 'date')  return 'date-custom';
    if (type == 'integer')  return 'inputnumber-custom';
    if (type == 'bigint')  return 'input-custom';
    if (type == 'email')  return 'input-custom';
    if (type == 'boolean')  return 'custom-checkbox';
    if (type == 'ownerlookup')  return 'input-custom';
    if (type == 'currency')  return 'input-currency-custom';
    if (type == 'website')  return 'input-custom';
    if (type == 'phone')  return 'input-custom';
    if (type == 'profileimage')  return 'input-custom';
    if (type == 'lookup' && this.zohoType === 'crm')  return 'lookup-custom';
    if (type == 'lookup' && this.zohoType === 'desk')  return 'lookup-autocomplete-input';
    if (type == 'ownerlookup')  return 'lookup-custom';
    if (type == 'percent')  return 'input-percent-custom';
    if (type == 'checkbox')  return 'custom-checkbox';
    if (type == 'multiselectpicklist')  return 'multi-select';
    return 'input-custom';
  }
*/

  usePlaceholder(type: string, placeholder: string): string {
    if (type == 'datetime' ||
        type == 'date' ||
        type == 'integer' ||
        type == 'email' ||
        type == 'currency'
    )  return '';
    return placeholder;
  }

  showLabel(type: string, displayLabel: string): string {
    return (type == 'boolean' || type == 'checkbox') ?  displayLabel : ''
  }

  getOptions(pickListValues: PickListValue[], type: string): any[] {
    const _options: any[] = [];
    if (pickListValues && pickListValues.length > 0) {
      pickListValues.forEach(_pickListValue => {
        let _option: any ;
        if (type === 'picklist') {
          _option = {
            label: _pickListValue.displayValue,
            value: _pickListValue.actualValue,
          }
        } else {
          _option = {
            name: _pickListValue.displayValue
          }
        }
        _options.push(_option);
      });
    }
    return _options;
  }

  getMetaData(module: string): Section[] {
   const metadataAsString = localStorage.getItem(module+'-metadata-'+this.zohoType);
   let sections = [];
   if (metadataAsString) {
      const metadataLayouts = JSON.parse(metadataAsString);
      metadataLayouts.forEach(metadataLayout => {
          if (this.useLayout) {
            if (metadataLayout.layoutId == this.currentLayoutId) {
              sections = metadataLayout.sections;
            }
          } else {
            sections = metadataLayout.sections;
          }
      });
    } else {
      this.messageService.showMessage('You must synchronize the Layouts','warning', true);
    }
    return sections || [];
  }

  getDefaultData(apiName: string, type: string): any {
    if (!this.recordData) return '';
    if (this.recordData[apiName]) {
      if (type === 'picklist') {
        return (this.recordData[apiName]['value']) ? this.recordData[apiName]['value'] : (this.recordData[apiName] ? this.recordData[apiName] : '') ;
      } else {
        if (type == 'datetime') {
          return this.convertDateTime(this.recordData[apiName]);
        }
        return this.recordData[apiName];
      }
    }
    return '';
  }

  getLookUpApiName(lookup: Lookup, type: string): string {
    if (type == 'lookup') return lookup.apiName;
    if (type == 'ownerlookup') return 'Owner';
  }

  getReadOnly(readOnly: boolean, apiName: string, type: string): boolean {
    /*
    let phone = 'Phone';
    if (this.zohoType === 'desk') { phone = 'phone'; }
    if (apiName == phone) {
      if (this.getDefaultData(phone, type)) {
        return true;
      }
    }

    let mobile = 'Mobile';
    if (this.zohoType === 'desk') { mobile = 'mobile'; }

    if (apiName == mobile) {
      if (this.getDefaultData(mobile, type)) {
        return true;
      }
    }
    */

    // if (apiName == 'Full_Name') {
    //    return true;
    // }
    return readOnly;
  }

  getRequired(required: boolean, apiName: string): boolean {
    if (apiName == 'First_Name') return true;
    if (apiName == 'Last_Name') return true;
    // if (apiName == 'Full_Name') return true;
    if (apiName == 'Phone') return true;
    return required;
  }

  getValidators(type: string): any {
    if (type == 'email') {
        return {
          email: {
            expression: (c: AbstractControl) =>
              !c.value || /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(c.value),
            message: (error: any, field: FormlyFieldConfig) =>
              `"${field.formControl!.value}" is not a valid Email`,
          },
        }
    }
    if (type == 'website') {
      return {
        URL: {
          expression: (c: AbstractControl) =>
            !c.value ||
            /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/i.test(c.value),
          message: (error: any, field: FormlyFieldConfig) =>
            `"${field.formControl!.value}" is not a valid URL`,
        }
      }
  }
    return {};
  }

  initFormly() {
    // if (this.useLayout) {
      this.loadedLayouts();
    // }
    this.initForm();
  }

  initForm(): void {
    if (!this.zohoType) {
      this.zohoType = 'crm';
    }
    this.form = new FormGroup({});
    this.model = {};
    this.options = {};
    this.fields = this.createFormlyFieldConfig();
    this.loaded = true;
  }

  convertDateTime(input: string): string {
    const inAsDate = new Date(input);
    const out = inAsDate.toISOString();
    return out;
  }

  cancel() {
    this.cancelAction.emit();
  }

  onFormFieldChanges(field: any, event: any): void {

    if (field.key === 'First_Name') {
      const firstName = field.formControl.value;
      if (firstName !== '') {
        let fullName = '' + firstName ;
        const lastName = this.findControlByName('Last_Name');
        if (lastName !== '') {
          fullName = firstName + ' ' + lastName;
        }
        this.setControlValueByName('Full_Name', fullName);
      }
    }

    if (field.key === 'Last_Name') {
      const lastName = field.formControl.value;
      if (lastName !== '') {
        let fullName =  '' + lastName;
        const firstName = this.findControlByName('First_Name');
        if (firstName !== '') {
          fullName = firstName + ' ' + lastName;
        }
        this.setControlValueByName('Full_Name', fullName);
      }
    }
  }

  findControlByName(controlName: string): any {
    let foundControl = null;
      const findInGroup = (group: any) => {
      Object.keys(group.controls).forEach((key) => {
        const control = group.get(key);
        if (key === controlName) {
          foundControl = control;
        } else if (control instanceof FormGroup) {
          findInGroup(control);
        }
      });
    };
    findInGroup(this.form);
    return foundControl ? foundControl.value : null;
  }

  setControlValueByName(controlName: string, newValue: any) {
    let controlToUpdate = null;
    const findInGroup = (group: any) => {
    Object.keys(group.controls).forEach((key) => {
      const control = group.get(key);
      if (key === controlName) {
        controlToUpdate = control;
      } else if (control instanceof FormGroup) {
         findInGroup(control);
      }
      });
    };
    findInGroup(this.form);
    if (controlToUpdate) {
      controlToUpdate.setValue(newValue);
    }
  }

  validateToAdd(apiName: string): boolean {
    if (this.zohoType === 'desk' && this.module === 'tickets') {
      if (this.recordData && this.recordData['id'] && apiName === 'contactId') {
        return false;
      }
    }
    return true;
  }

}

import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'getTimeFromTimeStamp',
	standalone: true
})
export class GetTimeFromTimeStampPipe implements PipeTransform {
	constructor(private datePipe: DatePipe) {}

	public transform(timeStamp: Date | number): string {
		let date: Date;

		if (typeof timeStamp === 'number') {
			date = new Date(timeStamp * 1000);
		} else {
			date = timeStamp;
		}

		return this.datePipe.transform(date, 'hh:mm a');
	}
}

<div class="voice-recorder">
	<div class="recording-interface" *ngIf="isRecording || isPaused">
		<div class="recording-controls">
			<button class="cancel-btn" (click)="onCancel()">
				<span class="material-icons">close</span>
			</button>

			<div class="recording-info">
				<div class="w-100">
					<app-audio-visualizer [isRecording]="isRecording"></app-audio-visualizer>
				</div>
				<div class="timer">{{ formatDuration(duration) }}</div>
			</div>

			<button class="control-btn" (click)="onPauseResume()">
				<span class="material-icons">
					{{ isPaused ? 'play_arrow' : 'pause' }}
				</span>
			</button>

			<button class="control-btn" (click)="onSend()">
				<span class="material-icons">send</span>
			</button>
		</div>
	</div>
</div>

import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
@Injectable({
	providedIn: 'root'
})
export class HistoryChatService {
	private isShowHistorySubject = new BehaviorSubject<boolean>(false);
	public isShowHistory$ = this.isShowHistorySubject.asObservable();

	public setHistoryValue(value: boolean): void {
		this.isShowHistorySubject.next(value);
	}
}

import {
	Component,
	ElementRef,
	EventEmitter,
	inject,
	Input,
	OnChanges,
	OnInit,
	Output,
	SimpleChanges,
	ViewChild
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { emitSelectedValues, MultiPickerFilterI, optionsFilter } from '../../interfaces/multi-picker-filter.interface';
import { SEVEN_DAYS } from '../../utils/constants';
import { SearchFieldComponent } from '../fields/search-field/search-field.component';

@Component({
	selector: 'app-bar-search',
	templateUrl: './bar-search.component.html',
	styleUrls: ['./bar-search.component.scss']
})
export class BarSearchComponent implements OnInit, OnChanges {
	@Input() options: MultiPickerFilterI[] = [
		{
			value: 'Filter by channel',
			label: 'Filter by channel',
			type: 'check',
			children: [
				{ value: 'Whatsapp', label: 'Whatsapp' },
				{ value: 'Telegram', label: 'Telegram' }
			]
		}
	];

	@Input() placeholder: string = 'chatComponent.SearchHere';
	@Input() numberFilters = 0;
	@Input() showDateFilter = false;
	@Input() showSearchAndFilterSection = true;
	@Input() showAgentFilter = false;

	@Output() onFromDateChangeEvent: EventEmitter<Date | null> = new EventEmitter<Date | null>();
	@Output() onToDateChangeEvent: EventEmitter<Date | null> = new EventEmitter<Date | null>();
	@Output() emitValue: EventEmitter<string> = new EventEmitter<string>();
	@Output() emitValues: EventEmitter<emitSelectedValues> = new EventEmitter<emitSelectedValues>();

	@ViewChild('searchField') searchField: SearchFieldComponent;

	private elementRef = inject(ElementRef);

	public dynamicForm!: FormGroup;

	public inputValue: string = '';
	public isFilter: boolean = false;
	public isViewFilter: boolean = false;
	public showChildren: boolean = false;
	public showCategory: boolean = false;
	public showOptions: boolean = false;
	public trueCounts: number[] = [];
	public selectedCategory: any = null;
	public categoryType: string = '';
	public isSelection: boolean = false;
	public dateFrom: Date | null = new Date();
	public dateTo: Date | null = new Date();

	private previousFromDate: Date | null = new Date();
	private previousToDate: Date | null = new Date();

	private filters: any = {};

	public ngOnInit(): void {
		this.setInitialDateRangeValues();
		this.dynamicForm = this.createFormGroup();
		this.subscribeToFormValueChanges();
	}

	private subscribeToFormValueChanges() {
		this.dynamicForm.valueChanges.subscribe((values: any) => {
			this.trueCounts = [];
			Object.values(values).forEach((element: any) => {
				this.trueCounts.push(this.countTrueValues(element));
				this.numberFilters = this.trueCounts.reduce((total, current) => total + current, 0);
			});
		});
	}

	public ngOnChanges(changes: SimpleChanges): void {
		if (changes.showAgentFilter && !changes.showAgentFilter.firstChange) {
			this.backToParentOption();
			this.isSelection = false;
			this.dynamicForm = this.createFormGroup();
			this.subscribeToFormValueChanges();
			this.numberFilters = 0;
			this.trueCounts = [];
			this.searchField?.clearInputValue();
			this.inputValue = '';
		}
	}

	private setInitialDateRangeValues(): void {
		this.dateTo = new Date();
		this.dateFrom = new Date();
		this.dateFrom.setDate(this.dateTo.getDate() - SEVEN_DAYS);
		this.previousFromDate = this.dateFrom;
		this.previousToDate = this.dateTo;
	}

	createFormGroup(): FormGroup {
		const group: any = {};

		this.options.forEach((category) => {
			const categoryGroup: any = {};
			category.children.forEach((child: optionsFilter) => {
				const control = new FormControl(category.type === 'check' ? false : '');
				categoryGroup[child.value] = control;
			});
			group[category.value] = new FormGroup(categoryGroup);
		});

		return new FormGroup(group);
	}

	countTrueValues(data: any): number {
		return Object.values(data).reduce((acc: number, value: any) => {
			if (Array.isArray(value)) {
				return acc + value.filter((v) => v === true).length;
			} else if (value === true) {
				return acc + 1;
			} else {
				return acc;
			}
		}, 0) as number;
	}

	clearFilters(event: MouseEvent): void {
		event.stopPropagation();
		Object.keys(this.dynamicForm.controls).forEach((categoryKey: string) => {
			const categoryGroup = this.dynamicForm.get(categoryKey) as FormGroup;
			Object.keys(categoryGroup.controls).forEach((controlKey: string) => {
				categoryGroup.get(controlKey)?.setValue(false);
			});
		});
		this.isSelection = this.numberFilters > 0;
		this.filters['filter'] = {};
		this.emitValue.emit(this.filters);
	}

	handleOptionsClick(event: MouseEvent) {
		event.stopPropagation();
	}

	public handleFieldKeyUp(event: string): void {
		this.filters['search'] = event;
		this.emitValue.emit(this.filters);
	}

	handleFilter(): void {
		this.backToParentOption();
		this.isViewFilter = !this.isViewFilter;
		this.showOptions = !this.showOptions;

		if (this.isViewFilter) {
			this.inputValue = this.searchField.inputValue;
		} else {
			setTimeout(() => {
				this.searchField.inputValue = this.inputValue;
			});
		}
	}

	viewOptionsChildren(category: any) {
		this.categoryType = category.type;
		this.selectedCategory = category;
		this.showCategory = true;
		this.showChildren = true;
	}

	backToParentOption() {
		this.selectedCategory = null;
		this.showChildren = false;
	}

	onSubmit() {
		this.showOptions = false;
		this.isFilter = false;
		this.isViewFilter = false;

		this.isSelection = this.numberFilters > 0;

		this.backToParentOption();
		this.filters['filter'] = this.groupByParameter(this.prepareData(this.dynamicForm.value));
		this.emitValue.emit(this.filters);
	}

	prepareData(valueForm: any): any {
		const filteredOptions: { value: string | number; name: string }[] = this.options.flatMap((option) =>
			option.children
				.filter((child) => {
					const childValue = child.value;
					return valueForm[option.value][childValue as string | number];
				})
				.map((child) => ({ parameter: option.value, value: child.value, name: child.label }))
		);

		return filteredOptions;
	}

	groupByParameter(data: any): any {
		return data.reduce((result: any, item: any) => {
			if (!result[item.parameter]) {
				result[item.parameter] = [];
			}
			result[item.parameter].push(item.value);
			return result;
		}, {});
	}

	handleClose(): void {
		this.isFilter = false;
		this.isViewFilter = false;
		this.isSelection = false;
	}

	public onFromDateChange(): void {
		if (!this.areDatesEqual(this.dateFrom, this.previousFromDate)) {
			this.previousFromDate = this.dateFrom;
			this.onFromDateChangeEvent.emit(this.dateFrom);
		}
	}

	public onToDateChange(): void {
		if (!this.areDatesEqual(this.dateTo, this.previousToDate)) {
			this.previousToDate = this.dateTo;
			this.onToDateChangeEvent.emit(this.dateTo);
		}
	}

	private areDatesEqual(date1: Date | null, date2: Date | null): boolean {
		if (date1 === date2) {
			return true;
		}
		if (date1 === null || date2 === null) {
			return false;
		}
		return date1.getTime() === date2.getTime();
	}

	public resetFilterValues(): void {
		this.setInitialDateRangeValues();
		this.searchField?.clearInputValue();
	}
}

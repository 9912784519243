import { Component, Input } from '@angular/core';

@Component({
	selector: 'app-table-agent',
	templateUrl: './table-agent.component.html',
	styleUrls: ['./table-agent.component.scss']
})
export class TableAgentComponent {
	@Input() agents = [
		{
			name: 'Abigail Anderson',
			id: 12454,
			startDate: 'Jan 4, 2024',
			endDate: 'Jul 10, 2024',
			duration: '2 h',
			channel: 'Whatsapp',
			status: 'Open',
			color: 'bg-warning',
			colorStatus: 'bg-secondary'
		},
		{
			name: 'Adrian Armstrong',
			id: 18654,
			startDate: 'Jan 4, 2024',
			endDate: 'Jul 10, 2024',
			duration: '5 min',
			channel: 'SMS',
			status: 'inactive',
			color: 'bg-danger',
			colorStatus: 'bg-secondary'
		},
		{
			name: 'Barbara Baker',
			id: 14522,
			startDate: 'Jan 4, 2024',
			endDate: 'Jul 10, 2024',
			duration: '10 min',
			channel: 'Telegram',
			status: 'inactive',
			color: 'bg-info',
			colorStatus: 'bg-secondary'
		},
		{
			name: 'Benjamin Barnes',
			id: 11891,
			startDate: 'Jan 4, 2024',
			endDate: 'Jul 10, 2024',
			duration: '2 h',
			channel: 'Whatsapp',
			status: 'inactive',
			color: 'bg-success',
			colorStatus: 'bg-secondary'
		},
		{
			name: 'Caleb Campbell',
			id: 10335,
			startDate: 'Jan 4, 2024',
			endDate: 'Jul 10, 2024',
			duration: '2 h',
			channel: 'Whatsapp',
			status: 'inactive',
			color: 'bg-secondary',
			colorStatus: 'bg-secondary'
		},
		{
			name: 'Daniel Davis',
			id: 11185,
			startDate: 'Jan 4, 2024',
			endDate: 'Jul 10, 2024',
			duration: '2 h',
			channel: 'Whatsapp',
			status: 'inactive',
			color: 'bg-warning',
			colorStatus: 'bg-secondary'
		}
	];

	@Input() cols: any[] = [
		{ field: 'name', header: 'Agent' },
		{ field: 'id', header: 'Id' },
		{ field: 'startDate', header: 'Start date' },
		{ field: 'endDate', header: 'End date' },
		{ field: 'duration', header: 'Duration' },
		{ field: 'channel', header: 'Channel' },
		{ field: 'status', header: 'Status' },
		{ field: 'actions', header: 'Actions' }
	];

	public listActions: any[] = [
		{
			label: 'View conversation',
			icon: 'remove_red_eye'
		},
		{
			label: 'Copy link to conversation',
			icon: 'share'
		}
	];
}

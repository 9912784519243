<div *ngIf="isFinish" class="d-lg-flex">
	<!-- start chat-leftsidebar -->
	<div class="chat-leftsidebar me-lg-1 ms-lg-0">
		<div class="tab-content">
			<!-- Start chats tab-pane -->
			<div id="pills-chat" role="tabpanel" aria-labelledby="pills-chat-tab">
				<!-- Start chats content -->
				<div class="chat-container">
					<ng-container *ngIf="!isShowHistory; else historyTitleTemplate">
						<div class="flex justify-content-between">
							<h4 class="mb-0">{{ 'Chats' | translate }}</h4>
							<div
								class="item-action"
								pTooltip="{{ 'Search chat' | translate }}"
								tooltipPosition="left"
								(click)="onSearchGeneralHistoryClick()"
							>
								<i class="material-symbols-outlined">overview</i>
							</div>
						</div>
					</ng-container>
					<ng-template #historyTitleTemplate>
						<div class="d-flex justify-content-between">
							<h4 class="mb-0">{{ 'History' | translate }}</h4>
							<div
								class="item-action"
								pTooltip="{{ 'Close history' | translate }}"
								tooltipPosition="left"
								(click)="closeHistory()"
							>
								<i class="material-symbols-outlined">close</i>
							</div>
						</div>
					</ng-template>

					<div *ngIf="hubConnection === hubConnectionState.Reconnecting" class="warning-alert">
						<span class="material-symbols-outlined icon"> wifi_off </span>
						<span>{{ 'Connection lost. Trying to reconnect.' | translate }}</span>
					</div>

					<div *ngIf="hubConnection === hubConnectionState.Disconnected" class="danger-alert">
						<span class="material-symbols-outlined icon"> signal_wifi_bad </span>
						<div class="text">
							<span>{{ 'connection_lost' | translate }}</span>
							<span class="refresh-link" (click)="refreshPage()">{{ 'refresh_page' | translate }}</span>
						</div>
					</div>

					<ng-container>
						<app-bar-search
							#barSearch
							[options]="filterOptions"
							[showDateFilter]="isShowHistory"
							[showAgentFilter]="isShowHistory"
							[showSearchAndFilterSection]="showSearchAndFilterSection"
							(emitValue)="handleFilter($event)"
							(onFromDateChangeEvent)="onFromDateChange($event)"
							(onToDateChangeEvent)="onToDateChange($event)"
						></app-bar-search>
					</ng-container>

					<!-- Start chat-message-list -->
					<div
						#chatPanel
						infinite-scroll
						[infiniteScrollContainer]="chat - message - list"
						[infiniteScrollUpDistance]="scrollUpDistance"
						[infiniteScrollDistance]="scrollDistance"
						[infiniteScrollThrottle]="throttle"
						[scrollWindow]="false"
						(scrolled)="onScroll($event)"
						class="chat-message-list"
					>
						<ng-container *ngIf="!isShowHistory; else historyTemplate">
							<ng-container *ngFor="let item of arrayServiceInstancesManager">
								<app-chat-conversation
									[serviceInstanceManager]="item"
									[useGeneratedImage]="useGeneratedImage"
									[chatSelected]="selectedChatBeforeHistoryShow"
									(dataChanged)="handleDataChanged($event)"
								></app-chat-conversation>
							</ng-container>
						</ng-container>
						<ng-template #historyTemplate>
							<ng-container *ngFor="let chat of conversationHistory; let i = index">
								<ul class="list-unstyled chat-list chat-user-list">
									<app-chat-history-item
										[chat]="chat"
										[isFirstItem]="i === 0"
										[useGeneratedImage]="useGeneratedImage"
										(dataChanged)="handleHistoryDataChanged(chat)"
									></app-chat-history-item>
								</ul>
							</ng-container>

							<div *ngIf="conversationHistory.length === 0" class="flex h-100 flex-column justify-content-center">
								<div *ngIf="!isLoadingHistoryMessages" class="flex justify-content-center fst-italic">
									{{ 'No data found' | translate }}
								</div>
								<div *ngIf="isLoadingHistoryMessages">
									<div class="d-flex justify-content-center">
										<div class="app-loading-spinner spinner-border"></div>
									</div>
								</div>
							</div>
						</ng-template>
					</div>
					<!-- End chat-message-list -->
				</div>
				<!-- Start chats content -->
			</div>
			<!-- End chats tab-pane -->
		</div>
	</div>

	<div class="w-100">
		<app-chat-welcome></app-chat-welcome>
		<!-- Start User chat -->
		<div class="user-chat w-100 overflow-hidden d-none" id="chat-room">
			<div class="w-100 d-lg-flex">
				<app-chat-content
					#chatContent
					*ngIf="isSelectedChat"
					class="w-100 d-flex"
					[conversationId]="selectedChat.id"
					[fullName]="selectedChat.customerContact"
					[messages]="selectedChat.messages"
					[phoneNumber]="selectedChat.clientPhone"
					[businnessPhoneNumber]="dataChannelSetupSelected.businessNumber"
					[nameChannel]="dataChannelSetupSelected.name"
					[serviceInstanceData]="serviceInstanceSelected"
					[groupedMessagesByDate]="selectedChat.groupedMessagesByDate"
					[setupId]="dataChannelSetupSelected.id"
					[deliveredMessageId]="sendIdMessage"
					[useGeneratedImage]="useGeneratedImage"
					[isLoadingMessages]="isLoadingMessages"
					(showHistory)="onShowHistory($event)"
					(chatClosed)="handleChatClosed($event)"
					(openPanel)="onChatPanelClicked($event)"
					(messageSended)="handleMessageSend($event)"
					(loadingMessage)="handleMessageLoading($event)"
					(transferMessage)="handleTransfer($event)"
				></app-chat-content>

				<!-- Chat Info Sidebar -->
				<ng-container *ngIf="!isShowHistory && isSelectedChat">
					<app-integration-panel
						[serviceInstance]="serviceInstanceSelected"
						(closePanel)="closeChatInfo($event)"
					></app-integration-panel>
				</ng-container>
			</div>
		</div>
	</div>
</div>

<div *ngIf="!isFinish" class="spinner-container">
	<div class="spinner-border"></div>
	<span class="">{{ 'Loading' | translate }}</span>
</div>

<swal
	#sucessfullTransfer
	[toast]="true"
	position="top-end"
	[showConfirmButton]="false"
	[timer]="5000"
	[timerProgressBar]="true"
	title="{{ 'Transfer successful' | translate }}"
	icon="success"
>
</swal>

import { Component, EventEmitter, OnDestroy, OnInit, Output, ViewChild, ViewContainerRef, inject } from '@angular/core';
import { Subscription } from 'rxjs';
import { HttpResponse } from '@angular/common/http';
import { ZohoDeskBackendService } from '../../../data-access/zoho-desk-backend.service';
import { emitData } from 'src/app/integrations/zoho/models/emitData.interface';
import { TranslateService } from '@ngx-translate/core';
import { TicketsDetailsComponent } from '../details/tickets-details.component';

@Component({
  selector: 'app-ticket-list',
  templateUrl: './ticket-list.component.html',
  styleUrls: ['./ticket-list.component.scss']
})
export class TicketDeskListComponent implements OnDestroy, OnInit {

  @ViewChild('childDetailsRecord', { read: ViewContainerRef }) container!: ViewContainerRef;

  @Output() emitAction: EventEmitter<emitData> = new EventEmitter<emitData>();

  private _ZohoDeskBackendService = inject(ZohoDeskBackendService);
  public translate = inject(TranslateService);
  showDetails = false;
  private subscriptions = new Subscription();
  public title?: string;
  public status?: any;
  public id?: any;
  public ticketDetails?: any;
  public department?: any;
  public contact?: any;
  loading = false;
  tickets = [];
  page = 0;
  visible: boolean = false;
  dataFilter?: any;
  isFiltering = false;
  display: boolean = false;
  showSeeMore = true;

  showDialog() {
    this.display = true;
  }

  hideDialog() {
    this.display = false;
  }

  ngOnInit(): void {
    setTimeout(() => {
      this.getTickets(false);
    });
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  getTickets(update: boolean): void {
    this.isFiltering = false;

    if (this.status === 'OPEN') {
      this.getTicketsOpen(update);
    }
    if (this.status === 'UNASSIGNED') {
      this.getTicketsUnassigned(update);
    }
    if (this.status === 'CONTACT') {
      this.getTicketsByContact(update);
    }
    if (this.status === 'TICKET-NUMBER') {
      this.getByTicketNumber(update);
    }
    if (this.status === 'SEARCH') {
      this.searchTickets(update);
    }
    if (this.status === 'SEARCH-AGENT') {
      this.searchTicketsByAgent(update);
    }
    if (this.status === 'OPEN-DETAILS') {
      this.getByTicketNumber(update);
    }
  }

  filterTickets(dataFilter: any, page: number): void {
    this.tickets = [];
    this.isFiltering = true;
    this.dataFilter = dataFilter;
    this.title = this.translate.instant('Integration.Zoho.Filtered tickets');
    this.filter(page);
  }

  filter(page: number): void {
    this.title = this.translate.instant('Integration.Zoho.Filtered tickets');
    this.loading = true;
    const createRecord$ = this._ZohoDeskBackendService
    .filterTickets(this.dataFilter.statusFilter, this.dataFilter.channelFilter, page)
    .subscribe((resp: HttpResponse<any>) => {
        if (resp.status == 200) {
          if (resp.body) {
            if (resp.body.length === 0) {
              this.showSeeMore = false;
            }
            this.tickets = [...this.tickets, ...resp.body];
          }
        }
        this.loading = false;
        this.subscriptions.add(createRecord$);
			},  (error: any) => {
        this.loading = false;
        this.emitError(error);
      });
  }

  getTicketsOpen(update: boolean): void {
    this.loading = true;
    const createRecord$ = this._ZohoDeskBackendService
    .getOpenTickets(update, this.page)
    .subscribe((resp: HttpResponse<any>) => {
        if (resp.status == 200) {
          if (resp.body) {
            if (resp.body.length === 0) {
              this.showSeeMore = false;
            }
            this.tickets = [...this.tickets, ...resp.body];
          }
        }
        this.loading = false;
        this.subscriptions.add(createRecord$);
			},  (error: any) => {
        this.loading = false;
        this.emitError(error);
      });
  }

  getTicketsUnassigned(update: boolean): void {
    this.loading = true;
    const createRecord$ = this._ZohoDeskBackendService
    .getUnassignedTickets(update, this.page)
    .subscribe((resp: HttpResponse<any>) => {
        if (resp.status == 200) {
          if (resp.body) {
            if (resp.body.length === 0) {
              this.showSeeMore = false;
            }
            this.tickets = [...this.tickets, ...resp.body];
          }
        }
        this.loading = false;
        this.subscriptions.add(createRecord$);
			},  (error: any) => {
        this.loading = false;
        this.emitError(error);
      });
  }

  getTicketsByContact(update: boolean): void {
    this.loading = true;
    const createRecord$ = this._ZohoDeskBackendService
    .getTickets(this.id, update, this.page)
    .subscribe((resp: HttpResponse<any>) => {
        if (resp.status == 200) {
          if (resp.body) {
            if (resp.body.length === 0) {
              this.showSeeMore = false;
            }
            this.tickets = [...this.tickets, ...resp.body];
          }
        }
        this.loading = false;
        this.subscriptions.add(createRecord$);
			},  (error: any) => {
        this.loading = false;
        this.emitError(error);
      });
  }

  getByTicketNumber(update: boolean): void {
    this.loading = true;
    const createRecord$ = this._ZohoDeskBackendService
    .getByTicketNumber(this.id, update, this.page)
    .subscribe((resp: HttpResponse<any>) => {
        if (resp.status == 200) {
          if (resp.body) {
            if (resp.body.length === 0) {
              this.showSeeMore = false;
            }
            this.tickets = [...this.tickets, ...resp.body];
          }
        }
        this.loading = false;
        this.subscriptions.add(createRecord$);
			},  (error: any) => {
        this.loading = false;
        this.emitError(error);
      });
  }

  searchTickets(update: boolean): void {
    this.loading = true;
    const createRecord$ = this._ZohoDeskBackendService
    .searchTickets(this.id, update)
    .subscribe((resp: HttpResponse<any>) => {
        if (resp.status == 200) {
          if (resp.body) {
            if (resp.body.length === 0) {
              this.showSeeMore = false;
            }
            this.tickets = resp.body;
          }
        }
        this.loading = false;
        this.subscriptions.add(createRecord$);
			},  (error: any) => {
        this.loading = false;
        this.emitError(error);
      });
  }

  searchTicketsByAgent(update: boolean): void {
    this.loading = true;
    const createRecord$ = this._ZohoDeskBackendService
    .searchTicketsByAgent(this.id, update, this.page)
    .subscribe((resp: HttpResponse<any>) => {
        if (resp.status == 200) {
          if (resp.body) {
            if (resp.body.length === 0) {
              this.showSeeMore = false;
            }
            this.tickets = [...this.tickets, ...resp.body];
          }
        }
        this.loading = false;
        this.subscriptions.add(createRecord$);
			},  (error: any) => {
        this.loading = false;
        this.emitError(error);
      });
  }

  ticketsDetails(ticket: any): void {
    this.emitAction.emit({
      action: 'ticket-details',
      values: ticket,
      module: ''
    });
  }

  ticketDetail(ticket: any): void {
		this.container.clear();
		const ticketsDetailsComponent = this.container.createComponent(TicketsDetailsComponent);
    ticketsDetailsComponent.instance.ticket = ticket;
    ticketsDetailsComponent.instance.currentDepartmentId = this.department.id;
		const ticketsDetailsEvent$ = ticketsDetailsComponent.instance.emitAction.subscribe((values) => {
			this.subscriptions.add(ticketsDetailsEvent$);
			if (values.action === 'cancel') {
				this.showDetails = false;
        this.container.clear();
        if (values.values) {
          this.reload();
        }
			}
      if (values.action === 'error') {
        this.emitError(values.values);
      }
		});
	}

  back() {
		this.emitAction.emit({
			action: 'cancel',
			values: this.ticketDetails,
      module: ''
		});
	}

  newTicket() {
		this.emitAction.emit({
			action: 'new-ticket',
			values: this.contact,
      module: ''
		});
	}

  getSeverity(ticket: any): string {
    if (ticket.status === 'Open' || ticket.status === 'Escalated') {
        return 'success';
    }
    if (ticket.status === 'On Hold') {
      return 'info';
    }
    if (ticket.status === 'Closed') {
      return 'success';
    }
    return 'warning';
  }

  uriOnDesk(id: string): string {
    const portalName = localStorage.getItem('ZohoDeskPortalName');
    return 'https://desk.zoho.com/support/' + portalName + '/ShowHomePage.do#Cases/dv/' + id;

  }

  openOnZoho(id: string) {
    window.open(this.uriOnDesk(id));
  }

  copy(id: string) {
    navigator.clipboard.writeText(this.uriOnDesk(id))
      .then(() => {
        this.emitAction.emit({
          action: 'copy-url',
          values: null,
          module: ''
        });
      })
      .catch((error) => {
          console.error("Error al copiar el texto: ", error);
      });
  }

  openDetails(ticket: any): void {
    this.showDetails = true;
    this.ticketDetail(ticket);
  }

  update(): void {
    this.getTickets(true);
  }

  emitError(error: any): void {
    this.emitAction.emit({
      action: 'error',
      values: error,
      module: ''
    });
	}

  seeMore(): void {
    this.page = this.page + 1;
    if (this.isFiltering) {
      this.filter(this.page);
    } else {
      this.getTickets(false);
    }
  }

  reload(): void {
    this.tickets = [];
    this.page = 0;
    this.getTickets(true);
  }

}

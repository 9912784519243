import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { MessageTransfer } from 'src/app/shared/interfaces/message-transfer.interface';
import { MultiPickerFilter } from 'src/app/shared/interfaces/multi-picker-filter.interface';

@Component({
	selector: 'app-dialog-transfer',
	templateUrl: './dialog-transfer.component.html',
	styleUrls: ['./dialog-transfer.component.scss']
})
export class DialogTransferComponent implements OnChanges {
	@Input() isVisible: boolean = true;
	@Input() options: MultiPickerFilter[] = [];
	@Input() optionsAgents: MultiPickerFilter[] = [];
	@Input() optionsBots: MultiPickerFilter[] = [];

	@Output() dialogClosed: EventEmitter<boolean> = new EventEmitter<boolean>();
	@Output() emitValue: EventEmitter<MessageTransfer> = new EventEmitter<MessageTransfer>();

	public messageTransfer: string = '';
	public toTransfer: string = 'agent';
	public chatTransferDisabled: boolean = true;
	public agentTransfer: any = null;
	public optionSelected: MultiPickerFilter | undefined = undefined;

	ngOnChanges(): void {
		this.options = this.optionsAgents;
	}

	handleTransferTo(): void {
		this.toTransfer === 'agent' ? (this.options = this.optionsAgents) : (this.options = this.optionsBots);
	}

	handleMessage(): void {
		this.chatTransferDisabled = this.messageTransfer.length === 0 || this.agentTransfer === null;
		if (this.agentTransfer)
			this.optionSelected = this.options.find((option) => option.label === this.agentTransfer.label);
	}

	handleTransfer(): void {
		this.isVisible = false;

		this.emitValue.emit({
			transferAgent: this.toTransfer === 'agent',
			messageTransfer: this.messageTransfer,
			toTransfer: this.optionSelected
		});
	}

	onModalClose(): void {
		this.messageTransfer = '';
		this.agentTransfer = null;
		this.dialogClosed.emit(false);
	}
}

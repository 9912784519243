
<h6  class="mb-1 pl-3" >
  <div class="row">
    <div class="col-1">
      <i
        tooltipPosition="left"
        (click)="back()"
        pTooltip="{{ 'Integration.Zoho.Back' | translate }}"
        class="pi pi-arrow-left mr-1"
      ></i>
    </div>
    <div class="col-10">
      Nuevo ticket
    </div>
  </div>
</h6>

<div *ngIf="loading" class="px-1 row">
	<div class="container-body">
    <div class="spinner-border" role="status">
      <span class="visually-hidden">Loading...</span>
    </div>
  </div>
</div>

<div *ngIf="!loading" class="mb-3" >
  <app-formly
    [recordData] = "record"
    [module] = "'tickets'"
    [useLayout] = true
    [titleCode] = ""
    (emitAction)="submit($event)"
    (cancelAction)="back()"
    [sending] = "sending"
    [zohoType] = "'desk'"
    [currentDepartmentId] = "currentDepartmentId"
  ></app-formly>
</div>


<div *ngIf="!isViewFilter && showSearchAndFilterSection" class="bar-search">
	<app-search-field
		#searchField
		*ngIf="showSearchAndFilterSection"
		[placeholder]="placeholder"
		(emitSearchValue)="handleFieldKeyUp($event)"
	></app-search-field>

	<p-button *ngIf="!isFilter && !isSelection" (click)="handleFilter()">
		<div class="flex flex-row align-items-center flex-wrap gap-2">
			<span class="label">{{ 'Filter' | translate }}</span>
			<i class="material-icons-outlined icon">filter_alt</i>
		</div>
	</p-button>

	<p-button *ngIf="isSelection" [outlined]="true" (click)="handleFilter()">
		<div class="flex flex-row align-items-center flex-wrap gap-2">
			<span class="label" translate="Numbers filters" [translateParams]="{ number: numberFilters }"></span>
			<i class="material-icons-outlined icon" (click)="clearFilters($event)">close</i>
		</div>
	</p-button>
</div>

<div *ngIf="isViewFilter" class="select-filters">
	<span class="text">{{ 'Select filters' | translate }}</span>
	<i class="material-icons-outlined font-size-16 cursor-pointer" (click)="handleFilter()">close</i>
</div>

<div *ngIf="showOptions" class="options custom-scrollbar swing-in-top-fwd" (click)="handleOptionsClick($event)">
	<form [formGroup]="dynamicForm">
		<div *ngFor="let category of options; let i = index">
			<div
				*ngIf="!selectedCategory || (selectedCategory === category && !showChildren)"
				class="mat-mdc-option justify-content-between swing-in-left-bck"
				[ngClass]="{ fadeOutRight: showChildren }"
				(click)="viewOptionsChildren(category)"
			>
				<p class="m-0" translate>{{ category.label }}</p>

				<div class="flex align-items-center flex-wrap gap-3">
					<span *ngIf="trueCounts[i] !== 0 && trueCounts.length !== 0" class="badge-primary">
						{{ trueCounts[i] }}
					</span>
					<i class="material-icons-outlined font-size-24 cursor-pointer">chevron_right</i>
				</div>
			</div>
			<!-- Check -->
			<div class="swing-in-right-bck" *ngIf="selectedCategory === category && showChildren && categoryType === 'check'">
				<div class="mat-mdc-option d-flex flex-row justify-content-start gap-3" (click)="backToParentOption()">
					<i class="material-icons-outlined font-size-24">chevron_left</i>
					<p class="d-flex align-items-center m-0">{{ 'Back' | translate }}</p>
				</div>
				<div formGroupName="{{ category.value }}">
					<div *ngFor="let child of category.children">
						<div class="mat-mdc-option gap-3">
							<p-checkbox [formControlName]="child.value" [binary]="true" [inputId]="child.label" />
							<label [for]="child.label">{{ child.label | translate }}</label>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div class="container-select-filters">
			<p-button class="w-100" (click)="onSubmit()">
				{{ 'Apply filters' | translate }}
			</p-button>
		</div>
	</form>
</div>

<div *ngIf="showDateFilter" class="flex gap-2 pt-2">
	<div>
		<span class="input-label">
			{{ 'From' | translate }}
		</span>
		<p-calendar
			[(ngModel)]="dateFrom"
			(ngModelChange)="onFromDateChange()"
			[iconDisplay]="'input'"
			[readonlyInput]="true"
			showIcon="true"
			class="history-calendar"
			panelStyleClass="calendar-input"
			inputStyleClass="console-input-form"
			dateFormat="dd/mm/yy"
			placeholder="dd/mm/yyyy"
			[maxDate]="dateTo"
		/>
	</div>
	<div>
		<span class="input-label">
			{{ 'To' | translate }}
		</span>
		<p-calendar
			[(ngModel)]="dateTo"
			(ngModelChange)="onToDateChange()"
			[iconDisplay]="'input'"
			[readonlyInput]="true"
			showIcon="true"
			class="history-calendar"
			panelStyleClass="calendar-input"
			inputStyleClass="console-input-form"
			dateFormat="dd/mm/yy"
			placeholder="dd/mm/yyyy"
			[minDate]="dateFrom"
		/>
	</div>
</div>

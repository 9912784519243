import { ConversationState } from '../enum/conversation-type.enum';
import { HandlerType } from '../enum/hadler-type.enum';

export class GetConversationHistoryQueryFilter {
	constructor(
		public offset: number = 0,
		public limit: number = 10,
		public handler: HandlerType = HandlerType.Agent,
		public customerPhoneNumber: string = null,
		public searchTerm: string = null,
		public fromDate: string = null,
		public toDate: string = null,
		public customerIds: Array<string> = new Array<string>(),
		public serviceInstanceIds: Array<string> = new Array<string>(),
		public conversationStates: Array<ConversationState> = new Array<ConversationState>()
	) {}
}

import { Component, Input, OnDestroy, OnInit } from '@angular/core';

@Component({
	selector: 'app-audio-visualizer',
	templateUrl: './audio-visualizer.component.html',
	styleUrls: ['./audio-visualizer.component.scss']
})
export class AudioVisualizerComponent implements OnInit, OnDestroy {
	@Input() set isRecording(value: boolean) {
		if (value) {
			this.startVisualization();
			return;
		}
		this.stopVisualization();
	}

	public visualBars: number[] = Array(20).fill(10);
	private animationFrame: number | null = null;
	private readonly MIN_HEIGHT = 5;
	private readonly MAX_HEIGHT = 40;
	private readonly BAR_COUNT = 30;

	public ngOnInit(): void {
		this.resetBars();
	}

	private resetBars(): void {
		this.visualBars = Array(this.BAR_COUNT).fill(this.MIN_HEIGHT);
	}

	private startVisualization() {
		const animate = () => {
			this.visualBars = this.generateBarHeights();
			this.animationFrame = requestAnimationFrame(animate);
		};
		animate();
	}

	private generateBarHeights(): number[] {
		return Array(this.BAR_COUNT)
			.fill(0)
			.map(() => Math.max(this.MIN_HEIGHT, Math.min(this.MAX_HEIGHT, Math.random() * this.MAX_HEIGHT)));
	}

	private stopVisualization() {
		if (this.animationFrame !== null) {
			cancelAnimationFrame(this.animationFrame);
			this.animationFrame = null;
		}
		this.resetBars();
	}

	public ngOnDestroy(): void {
		this.stopVisualization();
	}
}

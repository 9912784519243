import { Component, EventEmitter, OnDestroy, Output } from '@angular/core';
import { Subscription } from 'rxjs';
import { AudioRecorderService, RecordingState } from '../../../data-access/services/audio-recorder.service';

@Component({
	selector: 'app-voice-recorder',
	templateUrl: './voice-recorder.component.html',
	styleUrls: ['./voice-recorder.component.scss']
})
export class VoiceRecorderComponent implements OnDestroy {
	@Output() public audioRecorded = new EventEmitter<Blob>();
	@Output() public audioClose = new EventEmitter<void>();

	public duration = 0;
	public hasPermission = false;
	public isRecording = false;
	public isPaused = false;
	private subscriptions: Subscription[] = [];

	constructor(private audioRecorder: AudioRecorderService) {
		this.initSubscriptions();
	}

	private initSubscriptions(): void {
		this.subscriptions.push(
			this.audioRecorder.recordingState$.subscribe((state) => {
				this.isRecording = state === RecordingState.RECORDING;
				this.isPaused = state === RecordingState.PAUSED;
			})
		);

		this.subscriptions.push(
			this.audioRecorder.duration$.subscribe((duration) => {
				this.duration = duration;
			})
		);
	}

	public onPauseResume(): void {
		if (this.isPaused) {
			this.audioRecorder.resumeRecording();
		} else {
			this.audioRecorder.pauseRecording();
		}
	}

	public async onSend(): Promise<void> {
		try {
			const audioBlob = await this.audioRecorder.stopRecording();
			this.audioRecorded.emit(audioBlob);
		} catch (error) {
			console.error('Failed to save recording:', error);
		}
	}

	public onCancel(): void {
		this.audioRecorder.cancelRecording();
		this.audioClose.emit();
	}

	public formatDuration(seconds: number): string {
		const minutes = Math.floor(seconds / 60);
		const remainingSeconds = seconds % 60;
		return `${minutes}:${remainingSeconds.toString().padStart(2, '0')}`;
	}

	public ngOnDestroy(): void {
		this.audioRecorder.cancelRecording();
		this.subscriptions.forEach((subscription) => {
			subscription.unsubscribe();
		});
	}
}

<ng-container *ngFor="let channel of channelManager">
	<ul *ngFor="let conversation of channel.conversations" class="list-unstyled chat-list chat-user-list">
		<app-chat-conversation-item
			[chatSelected]="chatSelected"
			[conversationId]="conversation.id"
			[fullname]="conversation.customerContact"
			[phoneNumber]="conversation.clientPhone"
			[lastMessage]="conversation.messages[conversation.messages.length - 1]"
			[timestamp]="conversation.messages[conversation.messages.length - 1].timeStamp"
			[unSeenMessages]="conversation.unSeenMsg"
			[nameChannel]="channel.channelInfo.name"
			[useGeneratedImage]="useGeneratedImage"
			(dataChanged)="handleDataChanged($event)"
		></app-chat-conversation-item>
	</ul>
</ng-container>

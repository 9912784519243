import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { MediaStreamService } from './media-stream.service';

export enum RecordingState {
	IDLE = 'idle',
	RECORDING = 'recording',
	PAUSED = 'paused'
}

declare const lamejs: any;

@Injectable()
export class AudioRecorderService {
	private mediaRecorder: MediaRecorder | null = null;
	private audioChunks: Blob[] = [];
	private recordingStateSubject = new BehaviorSubject<RecordingState>(RecordingState.IDLE);
	private durationSubject = new BehaviorSubject<number>(0);
	private timerInterval: any;
	private audioContext: AudioContext | null = null;

	public recordingState$ = this.recordingStateSubject.asObservable();
	public duration$ = this.durationSubject.asObservable();

	constructor(private mediaStreamService: MediaStreamService) {}

	private async getAudioContext(): Promise<AudioContext> {
		if (!this.audioContext) {
			this.audioContext = new (window.AudioContext || (window as any).webkitAudioContext)();

			if (this.audioContext.state === 'suspended') {
				await this.audioContext.resume();
			}
		}
		return this.audioContext;
	}

	public async requestPermission(): Promise<boolean> {
		try {
			const stream = await this.mediaStreamService.requestMicrophoneAccess();
			this.setupMediaRecorder(stream);
			return true;
		} catch (error) {
			console.error('Microphone permission denied:', error);
			return false;
		}
	}

	private setupMediaRecorder(stream: MediaStream): void {
		if (this.mediaRecorder) {
			this.cleanupResources();
		}

		const mimeTypeSupported = this.getSupportedMimeType();
		this.mediaRecorder = new MediaRecorder(stream, {
			mimeType: mimeTypeSupported
		});

		this.mediaRecorder.ondataavailable = (event) => {
			if (event.data.size > 0) {
				this.audioChunks.push(event.data);
			}
		};
	}

	private getSupportedMimeType(): string {
		const types = ['audio/webm', 'audio/mp4'];
		return types.find((type) => MediaRecorder.isTypeSupported(type));
	}

	public async startRecording(): Promise<void> {
		if (!this.mediaRecorder || this.mediaRecorder.state === 'recording') {
			await this.requestPermission();
		}
		await this.getAudioContext();

		this.audioChunks = [];
		this.mediaRecorder?.start(10);
		this.recordingStateSubject.next(RecordingState.RECORDING);
		this.startTimer();
	}

	public pauseRecording(): void {
		if (this.mediaRecorder?.state === 'recording') {
			this.mediaRecorder.pause();
			this.recordingStateSubject.next(RecordingState.PAUSED);
			this.stopTimer();
		}
	}

	public resumeRecording(): void {
		if (this.mediaRecorder?.state === 'paused') {
			this.mediaRecorder.resume();
			this.recordingStateSubject.next(RecordingState.RECORDING);
			this.startTimer();
		}
	}

	public async stopRecording(): Promise<Blob> {
		return new Promise((resolve) => {
			if (this.mediaRecorder && this.mediaRecorder.state !== 'inactive') {
				this.mediaRecorder.onstop = async () => {
					const audioBlob = new Blob(this.audioChunks, { type: this.getSupportedMimeType() });
					const mp3Blob = await this.convertToMp3(audioBlob);
					this.resetRecording();
					this.cleanupResources();
					resolve(mp3Blob);
				};
				this.mediaRecorder.stop();
			} else {
				this.resetRecording();
				this.cleanupResources();
				resolve(new Blob());
			}
		});
	}

	private async convertToMp3(audioBlob: Blob): Promise<Blob> {
		const audioBuffer = await this.decodeIntoPCMFormat(audioBlob);
		const channelNumber = 0;
		const pcmData = audioBuffer.getChannelData(channelNumber);
		const sampleRate = audioBuffer.sampleRate;

		const monoChannel = 1;
		const bitrateValueInKbps = 128;
		const mp3Encoder = new lamejs.Mp3Encoder(monoChannel, sampleRate, bitrateValueInKbps);
		const mp3Data: Uint8Array[] = [];
		const samplesPerFrame = 1152;

		let position = 0;
		while (position < pcmData.length) {
			const pcmSlice = pcmData.subarray(position, position + samplesPerFrame);
			const mp3Frame = mp3Encoder.encodeBuffer(this.floatToInt16(pcmSlice));
			if (mp3Frame.length > 0) {
				mp3Data.push(new Uint8Array(mp3Frame));
			}
			position += samplesPerFrame;
		}

		const mp3End = mp3Encoder.flush();
		if (mp3End.length > 0) {
			mp3Data.push(new Uint8Array(mp3End));
		}

		const mp3Blob = new Blob(mp3Data, { type: 'audio/mpeg' });
		return mp3Blob;
	}

	private async decodeIntoPCMFormat(audioBlob: Blob): Promise<AudioBuffer> {
		const audioContext = await this.getAudioContext();
		const arrayBuffer = await audioBlob.arrayBuffer();
		const audioBuffer = await audioContext.decodeAudioData(arrayBuffer);
		return audioBuffer;
	}

	private floatToInt16(float32Array: Float32Array): Int16Array {
		const int16Array = new Int16Array(float32Array.length);
		for (let i = 0; i < float32Array.length; i++) {
			int16Array[i] = Math.max(-32768, Math.min(32767, float32Array[i] * 32768));
		}
		return int16Array;
	}

	public cancelRecording(): void {
		if (this.mediaRecorder && this.mediaRecorder.state !== 'inactive') {
			this.mediaRecorder.stop();
		}
		this.resetRecording();
		this.cleanupResources();
	}

	private resetRecording(): void {
		this.audioChunks = [];
		this.recordingStateSubject.next(RecordingState.IDLE);
		this.stopTimer();
		this.durationSubject.next(0);
	}

	private cleanupResources(): void {
		this.mediaStreamService.stopStream();
		this.mediaRecorder = null;
		if (this.audioContext) {
			this.audioContext.close();
			this.audioContext = null;
		}
	}

	private startTimer(): void {
		const startTime = Date.now() - this.durationSubject.value * 1000;
		this.timerInterval = setInterval(() => {
			const duration = Math.floor((Date.now() - startTime) / 1000);
			this.durationSubject.next(duration);
		}, 1000);
	}

	private stopTimer(): void {
		if (this.timerInterval) {
			clearInterval(this.timerInterval);
		}
	}
}

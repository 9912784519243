import { Injectable } from '@angular/core';

@Injectable()
export class MediaStreamService {
	private currentStream: MediaStream | null = null;

	public async requestMicrophoneAccess(): Promise<MediaStream> {
		if (this.currentStream) {
			return this.currentStream;
		}

		try {
			const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
			this.currentStream = stream;
			return stream;
		} catch (error) {
			console.error('Error accessing microphone:', error);
			throw error;
		}
	}

	public stopStream(): void {
		if (this.currentStream) {
			this.currentStream.getTracks().forEach((track) => {
				track.stop();
			});
			this.currentStream = null;
		}
	}

	public getCurrentStream(): MediaStream | null {
		return this.currentStream;
	}
}

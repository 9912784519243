import { HttpClient, HttpParams, HttpRequest } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { HubConnection, HubConnectionBuilder, HubConnectionState } from '@microsoft/signalr';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { BehaviorSubject, firstValueFrom, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Message } from '../interface/chat.interface';

@Injectable({
	providedIn: 'root'
})
export class MessagingHubService {
	private oidcSecurityService = inject(OidcSecurityService);
	private httpClient = inject(HttpClient);

	private maxTimeForRetry = 30000;
	private reconnectAttempts = 0;
	private maxReconnectAttempts = 10;
	private isStoppedManually = false;

	private connection: HubConnection;
	public connectionState$ = new BehaviorSubject<HubConnectionState>(HubConnectionState.Disconnected);
	private storedConnectionId: string | undefined;

	constructor() {
		this.initHub();
	}

	get connectionId(): string | undefined {
		return this.connection?.connectionId;
	}

	private initHub(): void {
		this.connection = new HubConnectionBuilder()
			.withUrl(environment.URL_API_SOCKET + '/console', {
				accessTokenFactory: () => firstValueFrom(this.oidcSecurityService.getAccessToken())
			})
			.withAutomaticReconnect([0, 2000, 5000, 10000, 20000])
			.withStatefulReconnect()
			.build();

		this.setupConnectionHandlers();
	}

	private handleReconnect(): void {
		if (!this.isStoppedManually) {
			if (this.reconnectAttempts < this.maxReconnectAttempts) {
				this.reconnectAttempts++;
				setTimeout(() => {
					this.startConnection();
				}, this.getReconnectDelay());
				return;
			}
			this.connectionState$.next(HubConnectionState.Disconnected);
			console.error('Max reconnect attempts reached. Please check your network connection.');
		}
		this.connectionState$.next(HubConnectionState.Disconnected);
	}

	private getReconnectDelay(): number {
		return Math.min(1000 * Math.pow(2, this.reconnectAttempts), this.maxTimeForRetry);
	}

	public startConnection(): Promise<void> {
		return this.connection
			.start()
			.then(() => {
				this.connectionState$.next(HubConnectionState.Connected);
				this.onConnected((connectionId) => (this.storedConnectionId = connectionId));
				this.reconnectAttempts = 0;
				this.isStoppedManually = false;
			})
			.catch((error: Error) => {
				console.error('Error connecting to SignalR hub:', error);

				const isUnauthorized = this.is401Error(error);
				if (isUnauthorized) {
					this.handle401Error();
					return;
				}

				this.handleReconnect();
			});
	}

	private is401Error(error: Error): boolean {
		const errorMessage = error.message || '';
		const isUnauthorized = /Status code '401'/.test(errorMessage);
		return isUnauthorized;
	}

	private handle401Error(): void {
		this.initHub();
		this.handleReconnect();
	}

	private setupConnectionHandlers(): void {
		this.onreconnecting();
		this.onreconnected();
		this.onclose();
	}

	private onreconnecting(): void {
		this.connection.onreconnecting((error) => {
			console.warn('Connection lost, attempting to reconnect...', error);
			this.connectionState$.next(HubConnectionState.Reconnecting);
		});
	}

	private onreconnected(): void {
		this.connection.onreconnected(() => {
			this.connectionState$.next(HubConnectionState.Connected);
		});
	}

	private onclose(): void {
		this.connection.onclose((error) => {
			console.error('Connection closed:', error);
			this.handleReconnect();
		});
	}

	public stopConnection(): void {
		this.isStoppedManually = true;
		this.connection.stop().catch((error) => console.error('Error stopping connection:', error));
	}

	onConnected(onConnected: (connectionId: string) => void): void {
		this.connection.on('connected', onConnected);
	}

	onReceiveMessage(receiveMessageCallback: (message: Message) => void): void {
		this.connection.on('ReceiveMessage', receiveMessageCallback);
	}

	closeConversation(conversationId: string, customerNotification: string): Promise<void> {
		return new Promise((resolve, reject) => {
			this.connection
				.send('CloseConversation', conversationId, customerNotification)
				.then(() => resolve())
				.catch((err) => reject(err));
		});
	}

	onConversationClosed(conversationClosedCallback: (conversationId: string) => void): void {
		this.connection.on('ConversationClosed', conversationClosedCallback);
	}

	sendMessage(message: Message, setupId: string = ''): Promise<void> {
		return new Promise((resolve, reject) => {
			this.connection
				.send(
					'SendMessage',
					message.id,
					message.conversationId,
					setupId,
					message.from,
					message.to,
					message.contact,
					message.channel,
					message.messageType,
					message.message
				)
				.then(() => resolve())
				.catch((err) => reject(err));
		});
	}

	checkConnectionState(): HubConnectionState {
		return this.connection.state;
	}

	public uploadFile(file: File, serviceInstanceId: string, setupId: string): Observable<any> {
		const url = environment.URL_API_SOCKET + '/v1/File/upload';

		const formData = new FormData();
		formData.append('file', file);
		formData.append('ServiceInstanceId', serviceInstanceId);
		formData.append('SetupId', setupId);

		const req = new HttpRequest('POST', url, formData, {
			reportProgress: true
		});

		return this.httpClient.request(req);
	}

	downloadFile(id: string, serviceInstanceId?: string, setupId?: string): Observable<any> {
		// Configura los parámetros de la consulta
		let params = new HttpParams().set('serviceInstanceId', serviceInstanceId).set('setupId', setupId);

		return this.httpClient.get(`${environment.URL_API_SOCKET}/v1/File/${id}/download`, {
			params: params,
			responseType: 'blob' as 'json',
			reportProgress: true,
			observe: 'events'
		});
	}
}

import { DOCUMENT } from '@angular/common';
import { HttpEventType } from '@angular/common/http';
import {
	ChangeDetectorRef,
	Component,
	ElementRef,
	Inject,
	inject,
	OnDestroy,
	OnInit,
	Renderer2,
	ViewChild
} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { HubConnectionState } from '@microsoft/signalr';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngxs/store';
import { SwalComponent } from '@sweetalert2/ngx-sweetalert2';
import { Howl } from 'howler';
import { catchError, forkJoin, of, Subscription } from 'rxjs';
import { ServiceInstanceService } from 'src/app/shared/data-access/service-instance.service';
import { MultiPickerFilterI } from 'src/app/shared/interfaces/multi-picker-filter.interface';
import { IServiceInstance } from 'src/app/shared/interfaces/service-instance.interface';
import { AuthState } from 'src/app/shared/states/auth.state';
import Swal from 'sweetalert2';
import { AgentItem } from '../../../agent/models/agent-item.model';
import { BasicInstanceInfo } from '../../../agent/models/basic-instance-info.model';
import { ConsoleBadResponse } from '../../../core/models/console-bad-response.model';
import { BarSearchComponent } from '../../../shared/ui/bar-search/bar-search.component';
import { SEVEN_DAYS } from '../../../shared/utils/constants';
import { ConversationState } from '../../data-access/enum/conversation-type.enum';
import { WhatsAppMessageStatus } from '../../data-access/enum/whatsapp-message.enum';
import { SetupChannelI } from '../../data-access/interface/configurator.interface';
import { IConversation, IConversationPage, IPager } from '../../data-access/interface/conversation.interface';
import { MessageType } from '../../data-access/interface/message.interface';
import { ServiceInstanceManagerI } from '../../data-access/interface/service-instance-manager.interface';
import { TransferMessage } from '../../data-access/interface/transfer-message.interface';
import { Chat } from '../../data-access/models/chat';
import { GetConversationHistoryQueryFilter } from '../../data-access/models/get-conversation-history-query-filter.model';
import { GetConversationQueryFilter } from '../../data-access/models/get-conversation-query-filter.model';
import { Message } from '../../data-access/models/message';
import { ConfiguratorService } from '../../data-access/services/configurator.service';
import { ConversationEventService } from '../../data-access/services/conversation-event.service';
import { HistoryChatService } from '../../data-access/services/history-chat.service';
import { MessagingHubService } from '../../data-access/services/messaging-hub.service';
import { TrafficService } from '../../data-access/services/traffic.service';
import { ChatContentComponent } from '../../ui/chat-content/chat-content.component';
import {
	findChannelById,
	findConversationById,
	findExistConversationById,
	findObjectById,
	getIndexConversationById,
	getLastTimeStamp,
	removeConversationById,
	sortConversation
} from '../../utils/chat-method';

@Component({
	selector: 'app-chat',
	templateUrl: './chat.component.html',
	styleUrls: ['./chat.component.scss']
})
export class ChatComponent implements OnInit, OnDestroy {
	@ViewChild('sucessfullTransfer') sucessfullTransfer: SwalComponent;
	@ViewChild('chatPanel') chatPanel: ElementRef;
	@ViewChild('barSearch') barSearch: BarSearchComponent;
	@ViewChild('chatContent') chatContent: ChatContentComponent;

	@Inject(DOCUMENT) private document: Document;

	private conversationEventService = inject(ConversationEventService);
	private serviceInstanceService = inject(ServiceInstanceService);
	private configuratorService = inject(ConfiguratorService);
	private messagingService = inject(MessagingHubService);
	private trafficService = inject(TrafficService);
	public translate = inject(TranslateService);
	private sanitizer = inject(DomSanitizer);
	private store = inject(Store);
	private router = inject(Router);
	private el = inject(ElementRef);
	private renderer = inject(Renderer2);
	private cd = inject(ChangeDetectorRef);

	public serviceInstancesData: IServiceInstance[] = [];
	public service;
	public message: string = '';
	public conversations: Chat[] = [];
	public conversationHistory: Chat[] = [];
	public selectedChat: Chat;
	public isSelectedChat = false;
	public isOpenModalIntegration: boolean = true;
	public isLoading: boolean = false;
	public limit: number = 10;
	public offset: number = 0;
	public isFetchingConversations: boolean = false;
	public stateSidebar = 'none';
	public isAuthenticated: boolean;
	public currentConversationId: string = '';
	public selectedChatBeforeHistoryShow: any | null = null;
	public sonido: Howl;
	public soundNotification: string = 'true';
	public serviceInstanceId: string = '';
	public serviceInstanceName: string = '';
	public setupName: string = '';
	public isFinish: boolean = false;

	public dataChannelSetup: SetupChannelI[] = [];
	public dataChannelSetupSelected: SetupChannelI = null;
	public setupIdArray: string[] = [];
	public arrayServiceInstancesManager: ServiceInstanceManagerI[] = [];
	public arrayServiceInstancesManagerCopy: ServiceInstanceManagerI[] = [];
	public serviceInstanceSelected: IServiceInstance = null;
	public scrollUpDistance = 2;
	public scrollDistance = 1;
	public throttle = 300;
	public numberConversations = 0;
	public numberHistory = 0;
	public offsetConversation = 0;
	public limitConversation = 10;
	public offsetHistory = 0;
	public limitHistory = 10;
	public pageConversation = 0;
	public pageHistory = 0;
	// Diccionario para almacenar el timestamp del último mensaje recibido por conversación
	private lastMessageTimestampByConversation: { [conversationId: string]: number } = {};
	private minIntervalBetweenMessages = 5 * 1000; // Intervalo mínimo entre mensajes en milisegundos (5 segundos)

	private readonly threshold = 0.95;

	public conversationStates: { [conversationId: string]: IPager } = {};
	public conversationHistoryStates: { [conversationId: string]: IPager } = {};
	public hubConnectionState = HubConnectionState;
	public hubConnection: HubConnectionState;

	public filterOptions: MultiPickerFilterI[] = [];
	public pendingMessagesQueue: Message[] = [];
	public sendIdMessage: string = '';
	public useGeneratedImage = false;
	public isShowHistory = false;
	public showSearchAndFilterSection = true;

	private networkStatusSubscription!: Subscription;
	public isLoadingMessages = false;
	public isLoadingHistoryMessages = false;
	private isGeneralHistorySearch = false;
	private generalHistorySearchQueryFilter: GetConversationHistoryQueryFilter = new GetConversationHistoryQueryFilter();
	private agentItems: AgentItem[];

	private historyChatService = inject(HistoryChatService);

	public ngOnInit(): void {
		this.historyChatService.setHistoryValue(false);
		this.isAuthenticated = this.store.selectSnapshot(AuthState.isAuthenticated);

		this.sonido = new Howl({
			src: ['../../../../assets/sound/notification_2.mp3'],
			volume: 0.9
		});
		this.soundNotification = localStorage.getItem('soundNotification')
			? localStorage.getItem('soundNotification')
			: 'true';

		this.generateImage();
		this.getServicesInstance();
		this.onReceiveMessage();
		this.watchHubConnectionStatus();
	}

	private isMediaMessage(messageType: MessageType): boolean {
		return [MessageType.Image, MessageType.Video, MessageType.Audio, MessageType.Sticker].includes(messageType);
	}

	private downloadAndSetMediaUrl(message: any): void {
		const serviceInstanceId = this.getIdServiceInstance(this.dataChannelSetupSelected.id);

		if (serviceInstanceId) {
			this.messagingService
				.downloadFile(message.message.id, serviceInstanceId, this.dataChannelSetupSelected.id)
				.subscribe((event) => {
					if (event.type === HttpEventType.Response) {
						message.message.url = this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(event.body!));
					}
				});
		}
	}

	private generateImage(): void {
		const isUseGeneratedImage = localStorage.getItem('useGeneratedImage');
		if (isUseGeneratedImage) {
			this.useGeneratedImage = isUseGeneratedImage === 'true';
		}
	}

	getServicesInstance(): void {
		this.serviceInstanceService.getServiceInstances(0).subscribe({
			next: (servicesInstances) => {
				if (servicesInstances.length === 0) {
					this.router.navigate(['/chat/no-channels']);
					return;
				}

				const userId = localStorage.getItem('userId');
				this.serviceInstancesData = servicesInstances.filter((item) =>
					item.config.Agents.WhiteList.some((agent) => agent.Id === userId)
				);

				this.createFilterServiceInstance();
				this.fillAgentItemsArray();

				// Recopilar todos los observables en un solo array
				const requests: any = this.serviceInstancesData.flatMap((servicesInstance) =>
					servicesInstance.config.Setups.map((setupInfo) =>
						this.configuratorService.getInformationConfig(setupInfo.SetupId).pipe(
							catchError((error) => of(null)) // Manejar errores devolviendo null en caso de error
						)
					)
				);

				// Ejecutar todas las solicitudes en paralelo usando forkJoin
				forkJoin(requests).subscribe({
					next: (responses: any[]) => {
						// Manejar la respuesta exitosa de las solicitudes
						const successfulResponses = responses.filter((response) => response !== null); // Filtrar respuestas exitosas
						this.dataChannelSetup.push(...successfulResponses); // Agregar respuestas exitosas al array de datos
						this.setupIdArray = this.dataChannelSetup.map((data) => data.businessNumber);

						this.isFinish = true;

						this.getConversations(true);
					},
					error: (error: any) => {
						// Manejar errores de las solicitudes
						console.error('Error fetching users:', error); // Registrar el error en la consola
					}
				});
			},
			error: (err) => console.error('Observable emitted an error: ' + err)
		});
	}

	createFilterServiceInstance(): void {
		const children = this.serviceInstancesData.map((instance) => ({
			value: instance.id,
			label: instance.name
		}));

		this.filterOptions.push({
			value: 'Filter by instance',
			label: 'Filter by instance',
			type: 'check',
			children: children
		});
	}

	private addAssignedToFilterOption(): void {
		const assignedToFilter = this.filterOptions.find((option) => option.value === 'AssignedTo');
		if (!assignedToFilter) {
			const children = this.agentItems.map((agent) => ({
				value: agent.id,
				label: agent.name
			}));

			this.filterOptions.push({
				value: 'AssignedTo',
				label: 'AssignedTo',
				type: 'check',
				children: children
			});
		}
	}

	private addConversationStateFilterOption(): void {
		const conversationStateFilter = this.filterOptions.find((option) => option.value === 'ConversationState');
		let conversationStatesValues: ConversationState[] = [ConversationState.Closed, ConversationState.Expired];
		if (!conversationStateFilter) {
			const children = conversationStatesValues.map((state) => ({
				value: state,
				label: this.getConversationStateLabel(state)
			}));

			this.filterOptions.push({
				value: 'ConversationState',
				label: 'ConversationState',
				type: 'check',
				children: children
			});
		}
	}

	private getConversationStateLabel(state: ConversationState): string {
		switch (state) {
			case ConversationState.Closed:
				return this.translate.instant('Closed');
			case ConversationState.Expired:
				return this.translate.instant('Unattended');
			default:
				return '';
		}
	}

	private fillAgentItemsArray(): void {
		const uniqueAgents = new Set<string>();
		this.agentItems = [];
		this.serviceInstancesData.forEach((instance) => {
			instance.config.Agents.WhiteList.forEach((agent) => {
				if (!uniqueAgents.has(agent.Id)) {
					uniqueAgents.add(agent.Id);
					this.agentItems.push(
						new AgentItem(agent.Id, agent.Name, [
							new BasicInstanceInfo(instance.id, instance.name, instance.config.Setups)
						])
					);
				} else {
					this.agentItems
						.find((agentItem) => agentItem.id === agent.Id)
						.basicInstanceInfo.push(new BasicInstanceInfo(instance.id, instance.name, instance.config.Setups));
				}
			});
		});
	}

	onReceiveMessage(): void {
		this.messagingService.onReceiveMessage((msg: Message) => {
			if (this.soundNotification === 'true') this.playNotificationSound(msg);

			if (this.isMediaMessage(msg.messageType)) this.downloadAndSetMediaUrl(msg);

			const indexServiceManager: number = findObjectById(this.arrayServiceInstancesManager, msg.to);
			const channelsByConfig = this.arrayServiceInstancesManager[indexServiceManager].config.channels;
			const indexChannel: number = findChannelById(channelsByConfig, msg.to);
			let conversationsByChannel: Chat[] =
				this.arrayServiceInstancesManager[indexServiceManager].config.channels[indexChannel].conversations;

			let chat = conversationsByChannel.find((c) => c.id == msg.conversationId);

			if (!findExistConversationById(this.arrayServiceInstancesManager, msg.conversationId)) {
				chat = this.getTransferredConversationData(msg, chat, indexServiceManager, indexChannel);
			} else {
				chat.addMessage(msg);
				this.selectedChat.id === chat.id ? null : chat.increaseUnSeenMsg();
				chat.setGroupedMessagesByDate();
			}

			const sortConversations = sortConversation(conversationsByChannel);
			conversationsByChannel = sortConversations;
		});
	}

	private getTransferredConversationData(
		msg: Message,
		chat: Chat,
		indexServiceManager: number,
		indexChannel: number
	): Chat {
		this.trafficService.getConversationById(msg.conversationId).subscribe((newConversation) => {
			chat = new Chat(
				msg.conversationId!,
				newConversation.customerContact!,
				'',
				newConversation.customerPhoneNumber!,
				msg.to!,
				msg,
				1,
				newConversation.channel
			);

			let newMessages: Message[] = [];

			newConversation.messages.forEach((newMessage, index, array) => {
				let message = new Message(
					newMessage.id!,
					msg.conversationId!,
					newMessage.message!,
					newMessage.receivedOn!,
					newMessage.messageType!,
					newMessage.to!,
					newMessage.from!,
					newMessage.contact!,
					this.setupIdArray.includes(newMessage.from) ? WhatsAppMessageStatus.Delivered : null,
					''
				);

				if (this.isMediaMessage(newMessage.messageType)) {
					this.dataChannelSetupSelected = this.searchSetup(newConversation.bussinessPhoneNumber);
					this.downloadAndSetMediaUrl(newMessage);
				}

				newMessages.unshift(message);
			});

			chat.setMessages(newMessages);
			this.arrayServiceInstancesManager[indexServiceManager].config.channels[indexChannel].conversations.push(chat);
			chat.setGroupedMessagesByDate();
		});
		return chat;
	}

	private watchHubConnectionStatus(): void {
		this.messagingService.connectionState$.subscribe((hubConnectionState: HubConnectionState) => {
			this.hubConnection = hubConnectionState;

			if (this.isOnline()) this.processPendingMessages();
		});
	}

	private processPendingMessages(): void {
		const messagesToSend = [...this.pendingMessagesQueue];

		messagesToSend.forEach((message, index) => {
			this.messagingService
				.sendMessage(message, message.setupId)
				.then(() => {
					setTimeout(() => {
						this.pendingMessagesQueue.splice(index, 1);
						this.sendIdMessage = message.id;
					});
				})
				.catch((error) => {
					message.status = WhatsAppMessageStatus.Error;
				});
		});
	}

	playNotificationSound(message: Message): void {
		const currentTime = Date.now();
		const conversationId = message.conversationId;
		const lastMessageTimestamp = this.lastMessageTimestampByConversation[conversationId] || 0;

		if (currentTime - lastMessageTimestamp > this.minIntervalBetweenMessages) {
			if (this.soundNotification === 'true') this.sonido.play();
			this.lastMessageTimestampByConversation[conversationId] = currentTime;
		}
	}

	private getConversations(firstTime: boolean = false): void {
		this.trafficService.conversation(this.offsetConversation, this.limitConversation).subscribe((_) => {
			this.numberConversations = _.pager.count;
			this.pageConversation += 1;
			this.arrayServiceInstancesManager = [];

			if (_.results?.length !== 0)
				_.results?.forEach((conversation, index, array) => {
					let messages: Message[] = [];

					conversation.messages?.forEach((msg, index, array) => {
						let message = new Message(
							msg.id!,
							conversation.id!,
							msg.message!,
							msg.receivedOn!,
							msg.messageType!,
							msg.to!,
							msg.from!,
							msg.contact!,
							this.setupIdArray.includes(msg.from) ? WhatsAppMessageStatus.Delivered : null,
							''
						);

						if (this.isMediaMessage(msg.messageType)) {
							this.dataChannelSetupSelected = this.searchSetup(conversation.bussinessPhoneNumber);
							this.downloadAndSetMediaUrl(msg);
						}

						messages.unshift(message);
					});

					let chat = new Chat(
						conversation.id!,
						conversation.customerContact,
						'',
						conversation.customerPhoneNumber!,
						conversation.bussinessPhoneNumber!,
						undefined,
						0,
						conversation.channel!,
						conversation.state
					);
					messages.forEach((value) => {
						chat.addMessage(value);
					});
					chat.setGroupedMessagesByDate();
					this.conversations.push(chat);
				});

			this.conversations = this.conversations.sort((conversacionA, conversacionB) => {
				const lastTimeStampA = getLastTimeStamp(conversacionA);
				const lastTimeStampB = getLastTimeStamp(conversacionB);

				return lastTimeStampB - lastTimeStampA;
			});

			this.isFetchingConversations = false;
			this.createObjectServiceInstanceManager();
			if (firstTime) this.checkIfShouldLoadMore();
			if (this.selectedChatBeforeHistoryShow) {
				this.handleDataChanged(this.selectedChatBeforeHistoryShow);
			}
		});
	}

	createObjectServiceInstanceManager(): void {
		this.serviceInstancesData.forEach((dataServiceInstance) => {
			const { id, name, config } = dataServiceInstance;
			const { Integrations, Bots, Extentions, Agents, Setups } = config;

			const serviceInstanceManager = {
				id,
				name,
				config: {
					integrations: Integrations,
					bots: Bots,
					extensiones: Extentions,
					agents: Agents,
					channels: []
				}
			};

			Setups.forEach((setupInfo) => {
				const filteredItemsDataChannel = this.dataChannelSetup.filter((item) => setupInfo.SetupId === item.id);

				if (filteredItemsDataChannel.length > 0) {
					const [channelInfo] = filteredItemsDataChannel;
					const conversations = this.conversations.filter((chat) => chat.businessPhone === channelInfo.businessNumber);

					serviceInstanceManager.config.channels.push({
						channelInfo,
						conversations
					});
				}
			});

			const exists = this.arrayServiceInstancesManager.some((element) => element.id === serviceInstanceManager.id);
			if (!exists) this.arrayServiceInstancesManager.push(serviceInstanceManager);
		});

		this.arrayServiceInstancesManagerCopy = this.arrayServiceInstancesManager;
	}

	private checkIfShouldLoadMore() {
		const element = this.chatPanel?.nativeElement;
		if (element) {
			const panelHeight = element.offsetHeight;
			const hasScroll = panelHeight > this.conversations.length * 92.1;
			const hasNextPage = this.pageConversation * this.limitConversation < this.numberConversations;
			if (hasScroll && hasNextPage) this.onScroll(null);
		}
	}

	private checkIfShouldLoadMoreHistory() {
		const element = this.chatPanel.nativeElement;
		const panelHeight = element.offsetHeight;
		const hasScroll = panelHeight > this.conversationHistory.length * 92.1;
		const hasNextPage = this.pageHistory * this.limitHistory < this.numberHistory;
		if (hasScroll && hasNextPage) this.onHistoryScroll();
	}

	public handleDataChanged(data: any): void {
		this.selectedChatBeforeHistoryShow = null;
		let chat = findConversationById(this.arrayServiceInstancesManager, data.conversationId);
		if (chat) {
			this.showUserChatPanel();
			chat!.unSeenMsg = 0;
			this.conversationEventService.emitEvent(chat);

			this.selectedChat = chat!;
			this.currentConversationId = data.conversationId;
			this.selectedChatBeforeHistoryShow = data;
			this.isSelectedChat = true;

			this.dataChannelSetupSelected = this.searchSetup(this.selectedChat.businessPhone);

			const indexServiceInstance: number = getIndexConversationById(
				this.arrayServiceInstancesManager,
				this.currentConversationId
			);
			this.serviceInstanceSelected = this.serviceInstancesData[indexServiceInstance];
			this.conversationEventService.emitEventTransfer(false);
		}
	}

	public handleHistoryDataChanged(chat: Chat): void {
		this.showUserChatPanel();

		this.selectedChat = chat!;
		this.currentConversationId = chat.id;
		this.dataChannelSetupSelected = this.searchSetup(this.selectedChat.businessPhone);
		this.isSelectedChat = true;
		this.cd.detectChanges();
	}

	private showUserChatPanel(): void {
		document.querySelector('.user-chat').classList.add('user-chat-show');
		document.querySelector('.chat-welcome-section').classList.add('d-none');
		document.querySelector('.user-chat').classList.remove('d-none');
	}

	handleMessageSend(message: Message): void {
		message.from = this.dataChannelSetupSelected.businessNumber;
		message.setupId = this.dataChannelSetupSelected.id;
		message.conversationId = this.selectedChat.id;
		message.channel = this.selectedChat.channel;

		this.selectedChat.setGroupedMessagesByDate();

		if (this.isOnline())
			this.messagingService
				.sendMessage(message, message.setupId)
				.then((_) => {
					this.sendIdMessage = message.id;
				})
				.catch((error) => {
					console.error('Failed to send message:', error);
					message.status = WhatsAppMessageStatus.Error;
				});
		else this.pendingMessagesQueue.push(message);
	}

	public handleFilter(event: any): void {
		if (this.isShowHistory) {
			this.resetHistoryPaginationVariables();
			if (event.search !== undefined) {
				if (!event.search.length) {
					this.generalHistorySearchQueryFilter.searchTerm = null;
				} else {
					this.generalHistorySearchQueryFilter.searchTerm = event.search;
				}
			}

			if (event.filter) {
				const agentIds = event.filter['AssignedTo'] as Array<string>;
				if (agentIds && agentIds.length) {
					this.generalHistorySearchQueryFilter.customerIds = [...agentIds];
				} else {
					this.generalHistorySearchQueryFilter.customerIds = [];
				}
				const filterInstanceIds = event.filter['Filter by instance'];
				if (filterInstanceIds && filterInstanceIds.length) {
					this.generalHistorySearchQueryFilter.serviceInstanceIds = [...filterInstanceIds];
				} else {
					this.generalHistorySearchQueryFilter.serviceInstanceIds = [];
				}

				const states = event.filter['ConversationState'] as Array<ConversationState>;
				if (states && states.length) {
					this.generalHistorySearchQueryFilter.conversationStates = [...states];
				} else {
					this.generalHistorySearchQueryFilter.conversationStates = [];
				}
			}

			this.historySearch(true);
			return;
		}

		if (event.search !== undefined && !this.isShowHistory) {
			this.conversationSearch(event);
			return;
		}

		if (event.filter) {
			const filterInstanceIds = event.filter['Filter by instance'];
			if (filterInstanceIds && filterInstanceIds.length > 0) {
				this.arrayServiceInstancesManager = this.arrayServiceInstancesManagerCopy.filter((instance) =>
					filterInstanceIds.includes(instance.id)
				);
			} else this.arrayServiceInstancesManager = [...this.arrayServiceInstancesManagerCopy];
		}
	}

	private conversationSearch(event: any): void {
		this.conversations = [];
		this.arrayServiceInstancesManager = [];

		if (event.search.length === 0) {
			this.getConversations();
		} else {
			let queryFilter = new GetConversationQueryFilter();
			queryFilter.searchTerm = event.search;
			this.trafficService.getConversation(queryFilter).subscribe((result) => {
				this.conversations = [];
				result.results?.forEach((conversation) => {
					let messages: Message[] = [];
					conversation.messages?.forEach((msg) => {
						let message = new Message(
							msg.id!,
							conversation.id!,
							msg.message!,
							msg.receivedOn!,
							msg.messageType!,
							msg.to!,
							msg.from!,
							msg.contact!,
							this.setupIdArray.includes(msg.from) ? WhatsAppMessageStatus.Delivered : null,
							''
						);

						if (this.isMediaMessage(msg.messageType)) {
							this.dataChannelSetupSelected = this.searchSetup(conversation.bussinessPhoneNumber);
							this.downloadAndSetMediaUrl(msg);
						}

						messages.unshift(message);
					});

					let chat = new Chat(
						conversation.id!,
						conversation.customerContact,
						'',
						conversation.customerPhoneNumber!,
						conversation.bussinessPhoneNumber!,
						undefined,
						0,
						conversation.channel!,
						conversation.state
					);
					messages.forEach((value) => {
						chat.addMessage(value);
					});
					chat.setGroupedMessagesByDate();
					this.conversations.push(chat);
				});

				this.conversations = this.conversations.sort((conversacionA, conversacionB) => {
					const lastTimeStampA = getLastTimeStamp(conversacionA);
					const lastTimeStampB = getLastTimeStamp(conversacionB);

					return lastTimeStampB - lastTimeStampA;
				});

				this.createObjectServiceInstanceManager();
			});
		}
	}

	private historySearch(isFirstTime: boolean = true): void {
		this.addAssignedToFilterOption();
		this.addConversationStateFilterOption();
		this.generalHistorySearchQueryFilter.offset = this.offsetHistory;
		this.generalHistorySearchQueryFilter.limit = this.limitHistory;
		this.isLoadingHistoryMessages = true;
		this.setDateRangeValuesIfEmpty();
		this.trafficService.getClientConversationHistory(this.generalHistorySearchQueryFilter).subscribe({
			next: (historyResult: IConversationPage) => {
				if (isFirstTime) {
					this.resetHistoryPaginationVariables();
					this.isLoadingHistoryMessages = false;
				}
				this.fillConversationHistoryList(historyResult);
				if (isFirstTime) this.checkIfShouldLoadMoreHistory();
			},
			error: () => {
				this.isLoadingHistoryMessages = false;
			}
		});
	}

	private setDateRangeValuesIfEmpty(): void {
		if (!this.generalHistorySearchQueryFilter.toDate) {
			const dateUTCMinus5 = this.convertToUTCMinus5(new Date());
			this.generalHistorySearchQueryFilter.toDate = `${dateUTCMinus5} 23:59:59`;
		}
		if (!this.generalHistorySearchQueryFilter.fromDate) {
			let fromDate = new Date();
			fromDate.setDate(fromDate.getDate() - SEVEN_DAYS);
			const dateUTCMinus5 = this.convertToUTCMinus5(fromDate);
			this.generalHistorySearchQueryFilter.fromDate = dateUTCMinus5;
		}
	}

	private fillConversationHistoryList(historyResult: IConversationPage) {
		const historyResultFilteredByInstanceId = historyResult.results?.filter((historyItem) =>
			this.dataChannelSetup.some((setupItem) => setupItem.id === historyItem.setupId)
		);

		if (historyResultFilteredByInstanceId?.length) {
			this.numberHistory = historyResult.pager.count;
			this.pageHistory += 1;
		}

		historyResultFilteredByInstanceId.forEach((conversation: IConversation) => {
			let messages: Message[] = [];

			conversation.messages?.forEach((msg) => {
				let message = new Message(
					msg.id!,
					conversation.id!,
					msg.message!,
					msg.receivedOn!,
					msg.messageType!,
					msg.to!,
					msg.from!,
					msg.contact!,
					this.setupIdArray.includes(msg.from) ? WhatsAppMessageStatus.Delivered : null,
					''
				);

				if (this.isMediaMessage(msg.messageType)) {
					this.dataChannelSetupSelected = this.searchSetup(conversation.bussinessPhoneNumber);
					this.downloadAndSetMediaUrl(msg);
				}

				messages.unshift(message);
			});

			let chat = new Chat(
				conversation.id!,
				conversation.customerContact,
				'',
				conversation.customerPhoneNumber!,
				conversation.bussinessPhoneNumber!,
				undefined,
				0,
				conversation.channel!,
				conversation.state
			);
			chat.createdOnTimeStamp = conversation.createdOn;

			messages.forEach((value) => {
				chat.addMessage(value);
			});
			chat.setGroupedMessagesByDate();
			this.conversationHistory.push(chat);
		});

		this.conversationHistory = this.conversationHistory.sort((conversacionA, conversacionB) => {
			return Number(conversacionB.createdOnTimeStamp) - Number(conversacionA.createdOnTimeStamp);
		});
	}

	private removeAssignedToFilterOption(): void {
		this.filterOptions = this.filterOptions.filter((option) => option.value !== 'AssignedTo');
	}

	private removeConversationStateFilterOption(): void {
		this.filterOptions = this.filterOptions.filter((option) => option.value !== 'ConversationState');
	}

	onChatPanelClicked() {
		if (this.stateSidebar === 'none') {
			(document.querySelector('.user-profile-sidebar') as HTMLElement).style.display = 'block';
			this.stateSidebar = 'block';
		} else {
			(document.querySelector('.user-profile-sidebar') as HTMLElement).style.display = 'none';
			this.stateSidebar = 'none';
		}
	}

	closeChatInfo(event: any) {
		(document.querySelector('.user-profile-sidebar') as HTMLElement).style.display = 'none';
		this.stateSidebar = 'none';
	}

	public onShowHistory(isShowHistory: boolean, isFirstTime: boolean = true) {
		this.resetHistoryPaginationVariables();
		this.isShowHistory = isShowHistory;
		if (isShowHistory) {
			this.showSearchAndFilterSection = false;
			if (!this.isGeneralHistorySearch) {
				this.generalHistorySearchQueryFilter.customerPhoneNumber = this.selectedChat?.clientPhone;
			}
			this.generalHistorySearchQueryFilter.offset = this.offsetHistory;
			this.generalHistorySearchQueryFilter.limit = this.limitHistory;

			this.isLoadingHistoryMessages = true;
			this.setDateRangeValuesIfEmpty();
			this.trafficService.getClientConversationHistory(this.generalHistorySearchQueryFilter).subscribe({
				next: (historyResult: IConversationPage) => {
					this.fillConversationHistoryList(historyResult);
					if (isFirstTime) this.checkIfShouldLoadMoreHistory();
					this.isLoadingHistoryMessages = false;
				},
				error: () => {
					this.isShowHistory = false;
					this.isLoadingHistoryMessages = false;
				}
			});
		}
	}

	private resetConversationList(): void {
		this.conversations = [];
		this.arrayServiceInstancesManager = [];
		this.getConversations();
	}

	private closeUserChat(): void {
		this.isSelectedChat = false;
	}

	private resetHistoryPaginationVariables(): void {
		this.conversationHistory = [];
		this.conversationHistoryStates = {};
		this.offsetHistory = 0;
		this.pageHistory = 0;
		this.numberHistory = 0;
	}

	handleChatClosed(messageClose: string): void {
		const { id: chatId } = this.selectedChat;

		this.messagingService
			.closeConversation(chatId, messageClose)
			.then(() => {
				Swal.fire({
					icon: 'success',
					title: this.translate.instant('endChat.title2'),
					text: this.translate.instant('endChat.text2')
				});
			})
			.catch((error) => {});

		this.conversationEventService.emitEventTransfer(true);

		this.arrayServiceInstancesManager = removeConversationById(this.arrayServiceInstancesManager, chatId);
		this.isSelectedChat = false;

		const userProfileSidebar = document.querySelector('.user-profile-sidebar') as HTMLElement | null;
		if (userProfileSidebar) userProfileSidebar.style.display = 'none';

		const chatWelcomeSection = document.querySelector('.chat-welcome-section') as HTMLElement | null;
		if (chatWelcomeSection) chatWelcomeSection.classList.remove('d-none');

		this.stateSidebar = 'none';
	}

	private initializeConversationState(conversationId: string): void {
		if (!this.isShowHistory) {
			if (!this.conversationStates[conversationId]) {
				this.conversationStates[conversationId] = {
					offset: 0,
					limit: this.limit,
					count: 0,
					page: 1
				};
			}
			return;
		}
		if (!this.conversationHistoryStates[conversationId]) {
			this.conversationHistoryStates[conversationId] = {
				offset: 0,
				limit: this.limit,
				count: 0,
				page: 1
			};
		}
	}

	public handleMessageLoading(data: any): void {
		if (!this.isLoadingMessages) {
			let chat = !this.isShowHistory
				? findConversationById(this.arrayServiceInstancesManager, data.conversationId)
				: this.selectedChat;

			this.initializeConversationState(data.conversationId);

			const conversationState = !this.isShowHistory
				? this.conversationStates[data.conversationId]
				: this.conversationHistoryStates[chat.id];
			conversationState.offset += conversationState.limit;
			conversationState.page += 1;

			if (conversationState.count === 0) {
				this.getMessages(data.conversationId, conversationState);
			} else if (conversationState.count > chat.messages.length) {
				this.getMessages(data.conversationId, conversationState);
			} else {
				this.isLoadingMessages = false;
			}
		}
	}

	getMessages(conversationId: string, conversationState: IPager): void {
		this.isLoadingMessages = true;
		this.trafficService.message(conversationState.offset, conversationState.limit, conversationId).subscribe(
			(resp) => {
				let messages: Message[] = [];
				conversationState.count = resp.pager.count;
				resp.results?.forEach((msg, index, array) => {
					const message = new Message(
						msg.id!,
						conversationId,
						msg.message!,
						msg.receivedOn!,
						msg.messageType!,
						msg.to!,
						msg.from!,
						msg.contact!,
						WhatsAppMessageStatus.Sent,
						''
					);

					if (this.isMediaMessage(msg.messageType)) this.downloadAndSetMediaUrl(msg);

					messages.unshift(message);
				});

				if (this.selectedChat.messages.length + messages.length <= resp.pager?.count!) {
					messages.forEach((value) => {
						this.selectedChat.addMessage(value);
					});
					this.selectedChat.setGroupedMessagesByDate();
				}

				if (this.currentConversationId !== conversationId) this.currentConversationId = conversationId;
				this.isLoadingMessages = false;
			},
			(err) => {
				this.isLoadingMessages = false;
			}
		);
	}

	getNameContact(conversation: Chat): string {
		const contactName = conversation.messages.filter((message) => this.setupIdArray.includes(message.to));
		return contactName.length !== 0 ? contactName[0].contact : '';
	}

	handleTransfer(object: TransferMessage): void {
		const body = {
			to: object.idUser,
			adapter: object.adapter,
			initialData: object.body
		};

		this.trafficService.transferConversation(this.selectedChat.id, body).subscribe({
			next: (_) => {
				this.arrayServiceInstancesManager = removeConversationById(
					this.arrayServiceInstancesManager,
					this.selectedChat.id
				);

				this.isSelectedChat = false;
				this.sucessfullTransfer.fire();
				this.conversationEventService.emitEventTransfer(true);

				const chatWelcomeSection = document.querySelector('.chat-welcome-section') as HTMLElement | null;
				if (chatWelcomeSection) chatWelcomeSection.classList.remove('d-none');
			},
			error: (err) => {
				if (err instanceof ConsoleBadResponse && this.isAgentOfflineError(err.detail)) {
					const errorTitle = this.translate.instant('AgentIsNotConnectedError');
					this.showErrorMessage(errorTitle);
					return;
				}
				this.showErrorMessage();
			}
		});
	}

	private isAgentOfflineError(errorDetail: string): boolean {
		const errorMessage = errorDetail || '';
		const isOffline = /AgentIsNotConnected/.test(errorMessage);
		return isOffline;
	}

	searchSetup(businessNumber: string): any | null {
		const setupData = this.dataChannelSetup.find((setup) => setup.businessNumber === businessNumber);
		return setupData ? setupData : null;
	}

	public onScroll(event: any): void {
		if (!this.isShowHistory) {
			this.offsetConversation += this.limitConversation;
			if (this.pageConversation * this.limitConversation < this.numberConversations) {
				this.getConversations();
			}
			return;
		}
		this.onHistoryScroll();
	}

	public onHistoryScroll(): void {
		this.offsetHistory += this.limitHistory;
		if (this.pageHistory * this.limitHistory < this.numberHistory) {
			this.isGeneralHistorySearch ? this.historySearch(false) : this.onShowHistory(true, false);
		}
	}

	public refreshPage(): void {
		window.location.reload();
	}

	public isOnline(): boolean {
		return this.hubConnection === HubConnectionState.Connected;
	}

	public closeHistory(): void {
		this.showSearchAndFilterSection = true;
		this.isGeneralHistorySearch = false;
		this.closeUserChat();
		this.clearBarSearchFilterValues();
		this.resetConversationList();
		this.removeAssignedToFilterOption();
		this.removeConversationStateFilterOption();
		this.isShowHistory = false;
		this.isGeneralHistorySearch = false;
		this.historyChatService.setHistoryValue(false);
	}

	private clearBarSearchFilterValues(): void {
		this.barSearch.resetFilterValues();
		this.generalHistorySearchQueryFilter = new GetConversationHistoryQueryFilter();
	}

	public onSearchGeneralHistoryClick(): void {
		this.isGeneralHistorySearch = true;
		this.isShowHistory = true;
		this.historyChatService.setHistoryValue(true);
		this.historySearch(true);
	}

	private getIdServiceInstance(idSetup: string): string {
		const serviceInstanceData = this.serviceInstancesData.find((item) =>
			item.config.Setups.some((setup: { SetupId: string }) => setup.SetupId === idSetup)
		);

		return serviceInstanceData ? serviceInstanceData.id : '';
	}

	private showErrorMessage(errorTitle: string = null): void {
		Swal.fire({
			toast: true,
			position: 'top-end',
			showConfirmButton: false,
			timer: 4000,
			timerProgressBar: true,
			icon: 'error',
			title: errorTitle || this.translate.instant('GenericErrorMessage')
		});
	}

	public onFromDateChange(fromDate: Date | null): void {
		this.resetHistoryPaginationVariables();
		if (fromDate) {
			const dateUTCMinus5 = this.convertToUTCMinus5(fromDate);
			this.generalHistorySearchQueryFilter.fromDate = dateUTCMinus5;
		} else {
			this.generalHistorySearchQueryFilter.fromDate = null;
		}
		this.isGeneralHistorySearch ? this.onSearchGeneralHistoryClick() : this.onShowHistory(true, true);
	}

	public onToDateChange(toDate: Date | null): void {
		this.resetHistoryPaginationVariables();
		if (toDate) {
			const dateUTCMinus5 = this.convertToUTCMinus5(toDate);
			this.generalHistorySearchQueryFilter.toDate = `${dateUTCMinus5} 23:59:59`;
		} else {
			this.generalHistorySearchQueryFilter.toDate = null;
		}
		this.isGeneralHistorySearch ? this.onSearchGeneralHistoryClick() : this.onShowHistory(true, true);
	}

	private convertToUTCMinus5(date: Date): string {
		const utcDate = new Date(date.getTime() + date.getTimezoneOffset() * 60000);
		const utcMinus5Date = new Date(utcDate.getTime() - 5 * 60 * 60000);

		const year = utcMinus5Date.getFullYear();
		const month = String(utcMinus5Date.getMonth() + 1).padStart(2, '0');
		const day = String(utcMinus5Date.getDate()).padStart(2, '0');

		return `${year}-${month}-${day}`;
	}

	public ngOnDestroy(): void {
		if (this.networkStatusSubscription) this.networkStatusSubscription.unsubscribe();
	}
}

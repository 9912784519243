<div class="ticket-container">
	<form class="form-ticket" [formGroup]="form">
		<span class="title text-center">Crear Ticket</span>

		<div class="flex flex-column gap-1">
			<label class="label">Tipo de usuario</label>
			<p-dropdown [options]="typeAccount" optionLabel="text" optionValue="value" formControlName="type_account" />
		</div>

		<div class="flex flex-column gap-1">
			<label class="label">Nombre de la compañia </label>
			<input type="text" pInputText class="form-control" formControlName="name_account" />
		</div>

		<div class="flex flex-column gap-1">
			<label class="label">Contacto</label>
			<input type="text" pInputText class="form-control" formControlName="name_contact" />
		</div>

		<app-search-field-ttc
			[accountType]="'Distribuidor'"
			[placeholder]="'Cuenta Distribuidora'"
			(accountContent)="accountContentAsigD($event)"
		></app-search-field-ttc>

		<app-search-field-ttc
      [preLoad]="true"
			[accountType]="'Aliado'"
			[placeholder]="'Cuenta Aliada'"
			(accountContent)="accountContentAsigA($event)"
		></app-search-field-ttc>

		<div class="flex flex-column gap-1">
			<label class="label">Tipo de soporte</label>
			<p-dropdown
				[options]="supportTicket"
				optionLabel="descripcion"
				optionValue="id"
				formControlName="support_type_id"
        [filter]="true"
        [showClear]="true"
			/>
		</div>

		<div class="flex flex-column gap-1">
			<label class="label">Tipo de caso</label>
			<p-dropdown
        [options]="ticketType"
        optionLabel="descripcion"
        optionValue="id"
        formControlName="ticket_type_id"
        [filter]="true"
        [showClear]="true"
      />
		</div>

    <div class="flex flex-column gap-1">
			<label class="label">Criticidad</label>
      <p-dropdown
        [options]="criticality"
        optionLabel="descripcion"
        optionValue="id"
        formControlName="criticality_id"
        [filter]="true"
        [showClear]="true"
      />
		</div>

		<div class="flex flex-column gap-1">
			<label class="label">Descripción</label>
			<textarea rows="8" pInputTextarea class="form-control-ta" formControlName="description"></textarea>
		</div>

		<div class="flex flex-row gap-3">
			<div class="col-md-6-custom text-center">
				<button type="button" class="btn btn-secondary btn-sm" (click)="cancel()">Cancelar</button>
			</div>
			<div class="col-md-6-custom text-center">
				<button type="button" class="btn btn-primary btn-sm" (click)="createTicket()">Crear</button>
			</div>
		</div>
	</form>
</div>

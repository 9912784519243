import { HttpEventType } from '@angular/common/http';
import {
	Component,
	ElementRef,
	EventEmitter,
	HostListener,
	inject,
	Input,
	OnChanges,
	Output,
	SimpleChanges,
	ViewChild
} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { catchError, EMPTY } from 'rxjs';
import { MessageType } from 'src/app/chat/data-access/enum/message-type.enum';
import { WhatsAppMessageStatus } from 'src/app/chat/data-access/enum/whatsapp-message.enum';
import { Message, MessageContent } from 'src/app/chat/data-access/models/message';
import { MessagingHubService } from 'src/app/chat/data-access/services/messaging-hub.service';
import { MimetypeService } from 'src/app/chat/data-access/services/mimetype.service';
import { ICustomer } from 'src/app/shared/interfaces/customer.interface';
import { IServiceInstance } from 'src/app/shared/interfaces/service-instance.interface';
import Swal from 'sweetalert2';
import { v4 as uuid } from 'uuid';
import { AudioRecorderService } from '../../../data-access/services/audio-recorder.service';
import { TextFormatMenuComponent } from '../text-format-menu/text-format-menu.component';

@Component({
	selector: 'app-typing-bar',
	templateUrl: './typing-bar.component.html',
	styleUrls: ['./typing-bar.component.scss']
})
export class TypingBarComponent implements OnChanges {
	@ViewChild('messageInput') messageInput: ElementRef;
	@ViewChild('textFormatComponent') textFormatComponent: TextFormatMenuComponent;
	showTextFormatMenu = false;

	@Input() serviceInstanceData: IServiceInstance | undefined = undefined;
	@Input() profile: ICustomer | undefined = undefined;
	@Input() phoneNumber: string = '';
	@Input() setupId: string = '';
	@Input() from: string = '';

	@Output() messageSend: EventEmitter<Message> = new EventEmitter<Message>();

	private _messagingService = inject(MessagingHubService);
	private _mimetypeService = inject(MimetypeService);
	private translateService = inject(TranslateService);
	private sanitizer = inject(DomSanitizer);
	private audioRecorder = inject(AudioRecorderService);

	public message: string = '';

	//Emoji
	public showEmojiPicker = false;
	public emoji = '';

	// File Upload
	public fileCaptured: File;
	public progress: number;
	public progressState: boolean;
	public img: any;
	public isMessageValid: boolean = false;
	public isRecordingAudio = false;
	public hasAudioPermission = false;

	onFocus() {
		this.showEmojiPicker = false;
	}

	onBlur() {}

	toggleEmojiPicker() {
		this.showEmojiPicker = !this.showEmojiPicker;
	}

	public ngOnChanges(changes: SimpleChanges): void {
		if (changes['phoneNumber']) {
			this.closeVoiceRecorder();
		}
	}

	addEmoji(event: any) {
		const { emoji } = this;
		const text = `${emoji}${event.emoji.native}`;
		this.message = `${this.message}${text}`;
		this.showEmojiPicker = false;

		if (this.messageInput) this.messageInput.nativeElement.focus();
		this.validateMessage();
	}

	public captureMedia(event: any): void {
		this.fileCaptured = event.target.files[0];
		let fileSizeMB = Number((this.fileCaptured.size / 1048576).toFixed(2));
		if ((this.fileCaptured.type === 'image/jpeg' || this.fileCaptured.type === 'image/png') && fileSizeMB >= 5) {
			event.target.value = '';
			this.showSendMediaSizeError();
			return;
		} else if ((this.fileCaptured.type === 'video/mp4' || this.fileCaptured.type === 'video/3gp') && fileSizeMB >= 16) {
			event.target.value = '';
			this.showSendMediaSizeError();
			return;
		} else if (fileSizeMB >= 100) {
			this.showSendMediaSizeError();
			event.target.value = '';
			return;
		}
		this.sendMedia();
		event.target.value = '';
	}

	private showSendMediaSizeError(): void {
		Swal.fire({
			toast: true,
			position: 'top-end',
			showConfirmButton: false,
			timer: 3000,
			timerProgressBar: true,
			title: this.translateService.instant('Limit exceeded'),
			icon: 'warning'
		});
	}

	private sendMedia(file: File = null): void {
		const fileToSend = file ? file : this.fileCaptured;
		this._messagingService
			.uploadFile(fileToSend, this.serviceInstanceData.id, this.setupId)
			.pipe(catchError(() => (this.showSendMediaSizeError(), EMPTY)))
			.subscribe((event) => {
				if (event.type === HttpEventType.Response) {
					let fileId = event.body.id;
					// Implementar servicio que devuelva el tipo de media a partir del type del fichero
					let type = this._mimetypeService.getMessageType(this._mimetypeService.mapFile(fileToSend)?.type!);
					let messageContent = this.createMessageContent(fileId, type, fileToSend);

					this.dispatchMessage(messageContent, type);
				}
			});
	}

	createMessageContent(id: string, messageType: MessageType, fileCaptured: File): MessageContent {
		let commonValues = {
			FileName: fileCaptured.name,
			Caption: fileCaptured.name
		};
		switch (messageType) {
			case MessageType.Text:
				return { id: id, mime_type: fileCaptured.type };
			case MessageType.Template:
				return { id: id, mime_type: fileCaptured.type };
			case MessageType.Document:
				return { id: id, ...commonValues, mime_type: fileCaptured.type };
			case MessageType.Video:
				return {
					id: id,
					Caption: fileCaptured.name,
					mime_type: fileCaptured.type
				};
			case MessageType.Audio:
				return { id: id, mime_type: fileCaptured.type };
			case MessageType.Sticker:
				return { id: id, mime_type: fileCaptured.type };
			case MessageType.Reaction:
				return { id: id, mime_type: fileCaptured.type };
			case MessageType.Location:
				return { id: id, mime_type: fileCaptured.type };
			case MessageType.Image:
				return { id: id, mime_type: fileCaptured.type };
			default:
				return { id: id, mime_type: fileCaptured.type };
		}
	}

	public messageButtonClick(): void {
		if (this.isMessageValid) {
			this.dispatchMessage(this.message, MessageType.Text);
		}
	}

	dispatchMessage(messageContent: any, messageType: MessageType = MessageType.Text) {
		let timestamp = Date.now();

		//  Si el mensaje es tipo texto se envia, pero si es de los tipos de media (audio, video, document, sticker) se crea objeto y se serializa en string
		let messageObj = new Message(
			uuid(),
			null,
			messageContent,
			timestamp,
			messageType,
			this.phoneNumber,
			this.from,
			this.profile.given_name,
			WhatsAppMessageStatus.Sending,
			''
		);

		if ([MessageType.Image, MessageType.Video, MessageType.Audio].includes(messageObj.messageType)) {
			this._messagingService
				.downloadFile(messageObj.message.id, this.serviceInstanceData.id, this.setupId)
				.subscribe((event) => {
					if (event.type === HttpEventType.Response) {
						messageObj.message.url = this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(event.body!));
					}
				});
		}
		this.messageSend.emit(messageObj);
		this.message = '';
		this.validateMessage();

		setTimeout(() => {
			this.clearInputAndResizeTextarea();
		});
	}

	@HostListener('keydown', ['$event'])
	onKeydown(event: KeyboardEvent) {
		if (this.isMessageValid) {
			if (event.key === 'Enter' && !event.shiftKey && this.message.length !== 0) {
				this.dispatchMessage(this.message, MessageType.Text);
			} else if (event.key === ' ' && event.shiftKey) event.preventDefault();
		}
	}

	public adjustTextareaHeight(): void {
		const MAX_HEIGHT = 200;
		const textarea = this.messageInput.nativeElement as HTMLTextAreaElement;
		textarea.style.height = 'auto';
		const newHeight = Math.min(textarea.scrollHeight, MAX_HEIGHT);
		textarea.style.height = `${newHeight}px`;
		textarea.style.overflowY = newHeight === MAX_HEIGHT ? 'auto' : 'hidden';
	}

	public clearInputAndResizeTextarea(): void {
		this.message = '';

		setTimeout(() => {
			if (this.messageInput) {
				this.adjustTextareaHeight();
			}
		});
	}

	public validateMessage(): void {
		this.isMessageValid = this.message.trim().length > 0;
	}

	public onTextSelect(): void {
		const textarea = this.messageInput.nativeElement as HTMLTextAreaElement;
		if (textarea.selectionStart !== textarea.selectionEnd && !this.textFormatComponent.isVisible) {
			this.textFormatComponent.textarea = textarea;
			this.textFormatComponent.showFormatMenu();
			return;
		}
		if (textarea.selectionStart === textarea.selectionEnd && this.textFormatComponent.isVisible) {
			this.textFormatComponent?.hideFormatMenu();
			return;
		}
	}

	public async showVoiceRecorder(): Promise<void> {
		if (!this.hasAudioPermission) {
			this.hasAudioPermission = await this.audioRecorder.requestPermission();
			if (!this.hasAudioPermission) {
				return;
			}
		}

		this.isRecordingAudio = true;
		try {
			await this.audioRecorder.startRecording();
		} catch (error) {
			console.error('Failed to start recording:', error);
			this.closeVoiceRecorder();
		}
	}

	public closeVoiceRecorder(): void {
		this.isRecordingAudio = false;
	}

	public onAudioRecorded(audioBlob: Blob): void {
		const mp3Extension = '.mp3';
		const file = new File([audioBlob], `voice${Date.now()}${mp3Extension}`, { type: audioBlob.type });
		this.sendMedia(file);
		this.closeVoiceRecorder();
	}
}

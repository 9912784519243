import { AgentConnectionState } from '../../core/enums/agent-connection-state.enum';
import { BasicInstanceInfo } from './basic-instance-info.model';

export class AgentItem {
	constructor(
		public id: string = null,
		public name: string = null,
		public basicInstanceInfo: Array<BasicInstanceInfo> = new Array<BasicInstanceInfo>(),
		public status: AgentConnectionState = AgentConnectionState.Closed
	) {}
}

<div *ngIf="loading">
  <div class="px-1 row">
    <div class="container-body">
      <div class="spinner-border" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>
  </div>
</div>

<div *ngIf="!loading">

  <p-messages

    [value]="messages"
    [enableService]="false"
    [closable]="false" />

  <div class="row mb-5 pl-1">
    <div class="col-4">
      <label>{{ 'Integration.Zoho.Module' | translate }}</label>
      <p-dropdown
        [options]="moduleOptions"
        [(ngModel)]="selectedModule"
        placeholder="Select a Module"
        (onChange)="onModuleChange()">
      </p-dropdown>
    </div>
    <div class="col-4">
      <label>{{ 'Integration.Zoho.Layout' | translate }}</label>
      <p-dropdown
        [options]="layoutOptions"
        [(ngModel)]="selectedLayout"
        placeholder="Select a Layout"
        [disabled]="!selectedModule"
        (onChange)="onLayoutChange()">
      </p-dropdown>
    </div>

    <div class="col-4 pt-5" style="text-align: right;" >
      <p-button
        (click)="confirm()"
        label="{{ 'Integration.Zoho.Sync with Zoho' | translate }}"
        icon="pi pi-arrow-right-arrow-left" size="small" class="mr-2"
        type="submit"
        [disabled]="loading"
      />
    </div>
  </div>
  <label>{{ 'Integration.Zoho.Fields' | translate }}</label>
    <div *ngIf="filteredSections.length > 0">
      <div *ngFor="let section of filteredSections" class="section">
        <h6>{{ section.displayName }}</h6>
        <div class="fields">
          <div *ngFor="let field of section.fields" class="field">
          <p>{{ field.displayName || field.apiName }}</p>
          <p-inputSwitch
            [disabled]="field.required"
            [(ngModel)]="field.showField"
          >
          </p-inputSwitch>
        </div>
      </div>
    </div>
  </div>
  <div style="text-align: center;">
    <button
      pButton
      type="button"
      label="{{ 'Integration.Zoho.Save' | translate }}"
      (click)="saveChanges()"
      icon="pi pi-save" size="small" class="mr-2"
      ></button>
  </div>
</div>

<p-confirmDialog
  key="syncFieldsConfirmDialog"
  rejectButtonStyleClass="p-button-outlined" >
  <ng-template pTemplate="message" let-message>
    <div class="custom-dialog-content">
      {{ message.message }}
    </div>
  </ng-template>
</p-confirmDialog>

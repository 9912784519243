import { Component, EventEmitter, inject, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { ConfirmPopup } from 'primeng/confirmpopup';
import { emitData } from 'src/app/integrations/zoho/models/emitData.interface';
import { ZohoCrmBackendService } from '../../../data-access/zoho-crm-backend.service';
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Subscription } from 'rxjs';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ConfirmationService, Message } from 'primeng/api';
import { TranslateService } from '@ngx-translate/core';
import { ZohoDeskBackendService } from 'src/app/integrations/zoho/zoho-desk/data-access/zoho-desk-backend.service';

@Component({
	selector: 'app-custom-fields-config',
	templateUrl: './custom-fields-config.component.html',
	styleUrls: ['./custom-fields-config.component.scss']
})
export class CustomFieldsConfigComponent implements OnInit, OnDestroy {

  public zohoType: string;

	@Output() emitAction: EventEmitter<emitData> = new EventEmitter<emitData>();
  private subscriptions = new Subscription();
  @ViewChild(ConfirmPopup) confirmPopup!: ConfirmPopup;
  public translate = inject(TranslateService);
  private confirmationService = inject(ConfirmationService);

  private _ZohoCrmBackendService = inject(ZohoCrmBackendService);
  private _ZohoDeskBackendService = inject(ZohoDeskBackendService);

  messages: Message[] | undefined;
  loading = false;

  jsonData: any[] = [];
  moduleOptions: any[] = [];
  layoutOptions: any[] = [];
  selectedModule: string | null = null;
  selectedLayout: string | null = null;
  filteredSections: any[] = [];

  constructor(private fb: FormBuilder) {}

  ngOnInit(): void {
    this.initInfoMessage();
    this.subscribeOnChanceInfoMessage();
    this.loadCrmConfiguration(false);
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  loadCrmConfiguration(update: boolean): void {
    this.loading = true;
    if (this.zohoType === 'crm') {
      const getCustomFieldsConfigurationEvent$ = this._ZohoCrmBackendService.getCustomFieldsConfiguration(update).subscribe(
        (resp: HttpResponse<any[]>) => {
          if (resp.status == 200) {
            this.jsonData = resp.body;
            this.initializeFilters();
            this.selectDefaultModuleAndLayout();
            this.loading = false;
          }
          this.subscriptions.add(getCustomFieldsConfigurationEvent$);
        },
        (error: HttpErrorResponse) => console.error(error)
      );
    }
    if (this.zohoType === 'desk') {
      const getCustomFieldsConfigurationEvent$ = this._ZohoDeskBackendService.getCustomFieldsConfiguration(update).subscribe(
        (resp: HttpResponse<any[]>) => {
          if (resp.status == 200) {
            this.jsonData = resp.body;
            this.initializeFilters();
            this.selectDefaultModuleAndLayout();
            this.loading = false;
          }
          this.subscriptions.add(getCustomFieldsConfigurationEvent$);
        },
        (error: HttpErrorResponse) => console.error(error)
      );
    }
  }

  selectDefaultModuleAndLayout() {
    if (this.moduleOptions.length > 0) {
      this.selectedModule = this.moduleOptions[0].value;
      this.onModuleChange();
    }
  }

  initializeFilters() {
    this.moduleOptions = this.jsonData.map(module => ({ label: module.module, value: module.module }));
  }

  onModuleChange() {
    const module = this.jsonData.find(mod => mod.module === this.selectedModule);
    if (module) {
      this.layoutOptions = module.layouts.map(layout => ({ label: layout.layoutName, value: layout.layoutId }));
      this.selectedLayout = null;
      this.filteredSections = [];
      if (this.layoutOptions.length > 0) {
        this.selectedLayout = this.layoutOptions[0].value;
        this.onLayoutChange();
      }
    }
  }

  onLayoutChange() {
    const module = this.jsonData.find(mod => mod.module === this.selectedModule);
    const layout = module?.layouts.find(lay => lay.layoutId === this.selectedLayout);
    this.filteredSections = layout?.sections || [];
  }

  saveChanges() {
    this.loading = true;
    if (this.zohoType === 'crm') {
      const updatCustomFieldsConfigurationEvent$ = this._ZohoCrmBackendService
        .updatCustomFieldsConfiguration(this.jsonData)
        .subscribe((res: HttpResponse<any>) => {
          this.subscriptions.add(updatCustomFieldsConfigurationEvent$);
          if (res.status == 200) {
            this.loading = false;
            const layoutMetaData: any = res.body;
            if (layoutMetaData != null) {
              localStorage.setItem('leads-metadata-crm', JSON.stringify(layoutMetaData.leadsSections));
              localStorage.setItem('contacts-metadata-crm', JSON.stringify(layoutMetaData.contactsSections));
              localStorage.setItem('deals-metadata-crm', JSON.stringify(layoutMetaData.dealsSections));
              localStorage.setItem('tasks-metadata-crm', JSON.stringify(layoutMetaData.tasksSections));
              localStorage.setItem('events-metadata-crm', JSON.stringify(layoutMetaData.eventsSections));
              localStorage.setItem('calls-metadata-crm', JSON.stringify(layoutMetaData.callsSections));
            }
            this.saveConfiguration();
          }
      });
    }
    if (this.zohoType === 'desk') {
      const updatCustomFieldsConfigurationEvent$ = this._ZohoDeskBackendService
        .updatCustomFieldsConfiguration(this.jsonData).subscribe((res: HttpResponse<any>) => {
          this.subscriptions.add(updatCustomFieldsConfigurationEvent$);
          if (res.status == 200) {
            this.loading = false;
            const layoutMetaData: any = res.body;
            if (layoutMetaData != null) {
              localStorage.setItem('tickets-metadata-desk', JSON.stringify(layoutMetaData.ticketsSections));
              localStorage.setItem('contacts-metadata-desk', JSON.stringify(layoutMetaData.clientsSections));
            }
            this.saveConfiguration();
          }
      });
    }
  }

  initInfoMessage(): void {
    this.messages = [{ severity: 'info', detail: this.translate.instant('Integration.Zoho.Select the Module and Layout and configure the fields to display in the forms.')}];
  }

  subscribeOnChanceInfoMessage(): void {
    this.translate.onLangChange.subscribe(() => {
      this.chanceInfoMessage();
    });
  }

  chanceInfoMessage(): void {
    this.translate.get([
      'Integration.Zoho.Select the Module and Layout and configure the fields to display in the forms.',
    ]).subscribe(translations => {
      this.messages = [{ severity: 'info', detail: translations['Integration.Zoho.Select the Module and Layout and configure the fields to display in the forms.'] }];
    });
  }

  confirm() {
    this.confirmationService.confirm({
        message: this.translate.instant('Integration.Zoho.Are you sure you want to synchronize the fields with Zoho? Please note that this action may overwrite the current configuration, and you will need to set it up again.'),
        header: 'Confirmation',
        icon: 'pi pi-info-circle',
        acceptIcon:"none",
        rejectIcon:"none",
        rejectButtonStyleClass:"p-button-text",
        accept: () => {
          this.loadCrmConfiguration(true);
        },
        key: 'syncFieldsConfirmDialog'
    });
  }

  saveConfiguration(): void {
    this.emitAction.emit({
			action: 'save-fields-configuration',
			values: null,
      module: null
		});
  }

}

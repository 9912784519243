<li (click)="sendConversation($event)" [ngClass]="{ active: isSelected }">
	<a href="javascript:void(0);">
		<div class="flex gap-4">
			<div class="chat-avatar flex">
				<div class="avatar-sm">
					<span *ngIf="useGeneratedImage" class="avatar-title rounded-circle bg-primary-subtle text-primary">
						<img
							class="rounded-circle"
							[src]="avatarUrl"
							(error)="onImageError()"
							(load)="onImageLoad()"
							alt="User Avatar"
						/>
					</span>
					<span *ngIf="!useGeneratedImage" class="avatar-title rounded-circle bg-primary-subtle text-primary">
						{{ chat?.customerContact.charAt(0) }}
					</span>
				</div>
			</div>
			<div class="d-flex flex-column justify-content-center flex-wrap flex-grow-1" style="min-width: 0">
				<h5 class="name mb-0">
					{{ getTime(chat.createdOnTimeStamp) }} {{ chat.createdOnTimeStamp | getTimeFromTimeStamp }}
				</h5>
			</div>
		</div>
	</a>
</li>

import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Store } from '@ngxs/store';
import { Observable, of, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { CustomerState } from 'src/app/shared/states/customer.state';
import { environment } from 'src/environments/environment';
import { ConsoleBadResponse } from '../../../core/models/console-bad-response.model';
import { ConversationState } from '../enum/conversation-type.enum';
import { HandlerType } from '../enum/hadler-type.enum';
import { AgentConnectionStatusI } from '../interface/agent-connection-status.interface';
import { IConversation, IConversationPage } from '../interface/conversation.interface';
import { IMessagePage } from '../interface/message.interface';
import { GetConversationHistoryQueryFilter } from '../models/get-conversation-history-query-filter.model';
import { GetConversationQueryFilter } from '../models/get-conversation-query-filter.model';

@Injectable({
	providedIn: 'root'
})
export class TrafficService {
	private baseUrl = environment.URL_API_TRAFFIC;
	private http = inject(HttpClient);
	private store = inject(Store);

	conversation(
		offset: number = 0,
		limit: number = 10,
		state: ConversationState = ConversationState.Active,
		handler: HandlerType = HandlerType.Agent,
		customerId: string = null
	): Observable<IConversationPage> {
		const customer = customerId || this.store.selectSnapshot(CustomerState.getCustomer).sub;
		let url_ =
			this.baseUrl +
			`/api/Conversation?offset=${offset}&limit=${limit}&State=${state}&handler=${handler}&AssignedTo=${customer}`;

		return this.http.get<any>(url_).pipe(catchError(this.handleError<any>('getMyData', [])));
	}

	public getClientConversationHistory(queryFilter: GetConversationHistoryQueryFilter): Observable<IConversationPage> {
		let url_ =
			this.baseUrl +
			`/api/Conversation?offset=${queryFilter.offset}&limit=${queryFilter.limit}` +
			`&handler=${HandlerType.Agent}`;

		if (queryFilter.conversationStates.length) {
			if (queryFilter.conversationStates.includes(ConversationState.Expired)) {
				url_ += `&handler=${HandlerType.Bot}`;
			}
			queryFilter.conversationStates.forEach((state) => {
				url_ += `&State=${state}`;
			});
		} else {
			url_ += `&State=${ConversationState.Closed}&State=${ConversationState.Archived}`;
		}

		if (queryFilter.customerPhoneNumber) {
			url_ += `&customerPhoneNumber=${queryFilter.customerPhoneNumber}`;
		}
		if (queryFilter.searchTerm) {
			url_ += `&searchTerm=${queryFilter.searchTerm}`;
		}
		if (queryFilter.fromDate) {
			url_ += `&createdOn_From=${queryFilter.fromDate}`;
		}
		if (queryFilter.toDate) {
			url_ += `&createdOn_To=${queryFilter.toDate}`;
		}

		if (queryFilter.customerIds.length) {
			queryFilter.customerIds.forEach((customerId) => {
				url_ += `&AssignedTo=${customerId}`;
			});
		}

		if (queryFilter.serviceInstanceIds.length) {
			queryFilter.serviceInstanceIds.forEach((serviceInstanceId) => {
				url_ += `&serviceInstanceId=${serviceInstanceId}`;
			});
		}

		url_ += '&sort=-createdOn';

		return this.http.get<IConversationPage>(url_).pipe(catchError(this.handleError<any>('getMyData', [])));
	}

	public getConversation(queryFilter: GetConversationQueryFilter): Observable<IConversationPage> {
		let customer = this.store.selectSnapshot(CustomerState.getCustomer);
		let params = new HttpParams()
			.set('offset', queryFilter.offset.toString())
			.set('limit', queryFilter.limit.toString())
			.set('State', queryFilter.state)
			.set('handler', queryFilter.handler)
			.set('AssignedTo', customer.sub)
			.set('searchTerm', queryFilter.searchTerm);

		// Make the HTTP GET request
		return this.http.get<IConversationPage>(`${this.baseUrl}/api/Conversation`, { params }).pipe(
			tap((response) => {
				// Process the response if needed
			}),
			catchError(
				this.handleError<IConversationPage>('getMyData', {
					/* Default value for IConversationPage */
				})
			)
		);
	}

	message(offset: number = 0, limit: number = 10, conversationId: string): Observable<IMessagePage> {
		let url_ = this.baseUrl + `/api/Message?offset=${offset}&limit=${limit}&conversationId=${conversationId}`;

		return this.http.get<IMessagePage>(`${url_}`).pipe(
			tap((_) => {}),
			catchError(this.handleError<any>('getMyData', []))
		);
	}

	private handleError<T>(operation = 'operation', result?: T) {
		return (error: any): Observable<T> => {
			console.error(error);
			return of(result as T);
		};
	}

	transferConversationToAgent(id: string, body: any): Observable<any> {
		return this.http.post(`${this.baseUrl}/api/Conversation/${id}/transfer/agent`, body);
	}

	transferConversation(id: string, body: any): Observable<any> {
		return this.http.post(`${this.baseUrl}/api/Conversation/${id}/transfer`, body).pipe(
			catchError((error: HttpErrorResponse) => {
				if (error.status === 400) {
					const badResponseError = error.error as ConsoleBadResponse;
					return throwError(
						() =>
							new ConsoleBadResponse(
								badResponseError.type,
								badResponseError.title,
								badResponseError.status,
								badResponseError.detail,
								badResponseError.traceId
							)
					);
				}
				return throwError(() => new Error('Generic error'));
			})
		);
	}

	public getConversationById(id: string): Observable<IConversation> {
		const url = `${this.baseUrl}/api/Conversation/${id}`;
		return this.http.get<IConversation>(url);
	}

	public getAgentsConnectionStatus(customerId: string): Observable<AgentConnectionStatusI[]> {
		const url = `${this.baseUrl}/api/Connections?customerId=${customerId}`;
		return this.http.get<AgentConnectionStatusI[]>(url);
	}
}

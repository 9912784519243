<div *ngIf="loaded" class="dinamic-formly ml-2 mr-2 mb-3">
    <h5 class="text-muted fw-normal mt-3">
      {{ titleCode | translate }}
    </h5>

    <div class="mb-3" *ngIf="useLayout">
      <h6>Layout</h6>
      <div class="flex flex-row gap-3">
        <p-dropdown
          class="w-full"
          [options]="currentLayouts"
          [(ngModel)]="currentLayoutId"
          optionLabel="label"
          optionValue="value"
          (onChange)="updateLayout()"
        />
      </div>
    </div>

    <form class="formly-form" *ngIf="currentLayoutId" [formGroup]="form">
      <formly-form
        [model]="model"
        [fields]="fields"
        [options]="options"
        [form]="form"
      ></formly-form>

      <div class="mb-2" *ngIf="form.invalid">
        <span class="invalidFieldsTitle"> {{ 'Integration.Zoho.Invalid Fields' | translate }}</span>
        <div *ngFor="let field of getInvalidFields(fields, form, '')">
          <span class="invalidFieldsError"> {{field}} </span>
        </div>
      </div>

      <div class="flex flex-row gap-3">
        <div class="col-md-6-custom text-center">
          <button
            [disabled]="sending"
            type="button"
            class="btn btn-secondary btn-sm"
            (click)="cancel()">
            {{ 'Integration.Zoho.Cancel' | translate }}
          </button>
        </div>
        <div class="col-md-6-custom text-center">
          <button
            [disabled]="sending"
            type="button"
            class="btn btn-primary btn-sm"
            (click)="submit()">
            {{ ((recordData && recordData['id']) ? 'Integration.Zoho.Update' : 'Integration.Zoho.Save') | translate }}
          </button>
        </div>
      </div>

    </form>
  </div>


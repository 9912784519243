<div class="grid p-3" [class.justify-content-end]="isRecordingAudio">
	<ng-container *ngIf="!isRecordingAudio">
		<div class="col m-0">
			<div>
				<app-text-format-menu #textFormatComponent></app-text-format-menu>
			</div>
			<textarea
				#messageInput
				class="form-control form-control-lg bg-light border-light"
				placeholder="{{ 'Enter Message...' | translate }}"
				[(ngModel)]="message"
				rows="1"
				(blur)="onBlur()"
				(focus)="onFocus()"
				(input)="adjustTextareaHeight(); validateMessage()"
				(selectionchange)="onTextSelect()"
			></textarea>
		</div>
		<div class="col-fixed m-0 container-options">
			<ul class="list-inline mb-0">
				<li class="list-inline-item">
					<button
						type="button"
						class="btn btn-link text-decoration-none font-size-16 btn-lg waves-effect"
						data-toggle="tooltip"
						data-placement="top"
						pTooltip="{{ 'Emoji' | translate }}"
						tooltipPosition="top"
						(click)="toggleEmojiPicker()"
						id="emoji-btn"
					>
						<i class="ri-emotion-happy-line"></i>
					</button>
					<emoji-mart
						class="emoji-mart"
						*ngIf="showEmojiPicker"
						(emojiSelect)="addEmoji($event)"
						title="Pick your emoji…"
					></emoji-mart>
				</li>
				<li class="list-inline-item">
					<label id="files" class="btn btn-link text-decoration-none font-size-16 btn-lg waves-effect">
						<i class="ri-attachment-line"></i>
						<input
							name="fileInput"
							size="60"
							type="file"
							class="form-control-file d-none"
							(change)="captureMedia($event)"
						/>
					</label>
				</li>
				<ng-container *ngIf="isMessageValid; else voiceRecorderIcon">
					<li class="list-inline-item">
						<button
							type="submit"
							class="btn btn-primary font-size-16 btn-lg chat-send waves-effect waves-light"
							(click)="messageButtonClick()"
						>
							<i class="ri-send-plane-2-fill"></i>
						</button>
					</li>
				</ng-container>
				<ng-template #voiceRecorderIcon>
					<li class="list-inline-item">
						<button
							class="btn btn-primary font-size-16 btn-lg waves-effect waves-light btn-mic"
							(click)="showVoiceRecorder()"
						>
							<i class="material-symbols-outlined typing-mic">mic</i>
						</button>
					</li>
				</ng-template>
			</ul>
		</div>
	</ng-container>
	<ng-container *ngIf="isRecordingAudio">
		<div class="voice-recorder-container">
			<app-voice-recorder
				(audioRecorded)="onAudioRecorded($event)"
				(audioClose)="closeVoiceRecorder()"
			></app-voice-recorder>
		</div>
	</ng-container>
</div>
